import {IAppThunkActionStates} from "../types/thunk";
import {
  actions as userActions,
  actionTypes as userActionTypes,
} from "../User/actions";
import {actions, actionTypes} from "./actions";
import {ICourse, IUsersEntities} from "./types";

export type ThunkActionsNames =
  | "privacyNotification"
  | "privacySubscriptionNotification"
  | "loadCourses"
  | "getCourseHistory";

export interface IStateUi {
  isListFail?: boolean;
  isListPending?: boolean;
  isListSuccess?: boolean;
  listError?: string;

  isDeletePending?: {[key: number]: boolean};
  isDeleteSuccess?: boolean;

  isDetailFail?: boolean;
  isDetailSuccess?: boolean;
  detailError?: string;

  isPending?: boolean;
  isSuccess?: boolean;

  enableModalIsOpen?: boolean;
  disableModalIsOpen?: boolean;

  isResetPasswordContractorFail?: boolean;
  isResetPasswordContractorPending?: boolean;
  isResetPasswordContractorSuccess?: boolean;
  resetPasswordContractorError?: string;

  isUploadBulkFail?: boolean;
  isUploadBulkPending?: boolean;
  isUploadBulkSuccess?: boolean;
  uploadBulkError?: string;

  isAcceptBulkFail?: boolean;
  isAcceptBulkPending?: boolean;
  isAcceptBulkSuccess?: boolean;
  acceptBulkError?: string;
}

export interface IStateNewUser {
  isPending?: boolean;
  isSuccess?: boolean;
  isFail?: boolean;
  error?: string;
  userId?: number;
}

export interface IState {
  entities: IUsersEntities;
  thunkActionsStates: {
    [key in ThunkActionsNames]?: {[key: string]: IAppThunkActionStates};
  };
  coursesHistory?: ICourse[] | null;
  list: string[];
  newUser: IStateNewUser;
  report: Array<{[Key: string]: string}>;
  filePath?: string;
  ui: IStateUi;
}

const userInitialState = {
  entities: {},
  thunkActionsStates: {},
  list: [],
  newUser: {},
  report: [],
  ui: {},
};

export const reducer = (
  state: IState = userInitialState,
  action: actions | userActions
): IState => {
  switch (action.type) {
    case userActionTypes.LOGOUT_SUCCESS:
      return {
        ...userInitialState,
      };

    case actionTypes.MERGE_ITEMS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        entities: {...state.entities, ...action.payload},
      };

    case actionTypes.SET_IS_PENDING:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: true,
              isSuccess: false,
              isFail: false,
              error: undefined,
            },
          },
        },
      };
    case actionTypes.SET_IS_SUCCESS:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: false,
              isSuccess: true,
              isFail: false,
              error: undefined,
            },
          },
        },
      };
    case actionTypes.SET_IS_FAIL:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: false,
              isSuccess: false,
              isFail: true,
              error:
                action.error.message ?? "Errore sconosciuto, riprova più tardi",
            },
          },
        },
      };
    case actionTypes.RESET_STATE:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: false,
              isSuccess: false,
              isFail: false,
              error: undefined,
            },
          },
        },
      };

    case actionTypes.LIST_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isListFail: false,
          isListPending: true,
          isListSuccess: false,
          listError: undefined,
        },
      };
    case actionTypes.LIST_SUCCESS:
      return {
        ...state,
        list: [...action.payload],
        ui: {
          ...state.ui,
          isListFail: false,
          isListPending: false,
          isListSuccess: true,
          listError: undefined,
        },
      };
    case actionTypes.LIST_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isListFail: true,
          isListPending: false,
          isListSuccess: false,
          listError:
            action.payload && action.payload.message
              ? action.payload.message
              : "Errore sconosciuto, riprova più tardi",
        },
      };
    case actionTypes.CREATE_REQUEST:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          error: undefined,
          isFail: false,
          isPending: true,
          isSuccess: false,
        },
      };
    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          isPending: false,
          isSuccess: true,
        },
      };
    case actionTypes.CREATE_FAIL:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          error:
            action.payload && action.payload.error.message
              ? action.payload.error.message
              : "Errore sconosciuto, riprova più tardi",
          isFail: true,
          isPending: false,
          userId: action.payload.userId,
        },
      };
    case actionTypes.RESET_NEW_USER_STATE:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          error: undefined,
          isFail: false,
          isPending: false,
          isSuccess: false,
        },
        ui: {
          isPending: false,
          isSuccess: false,
        },
      };

    case actionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        ui: {
          isDetailFail: false,
          isDetailSuccess: true,
          isPending: false,
          isSuccess: true,
        },
      };
    case actionTypes.UPDATE_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          detailError:
            action.payload && action.payload.message
              ? action.payload.message
              : "Errore sconosciuto, riprova più tardi",
          isDetailFail: true,
          isDetailSuccess: false,
        },
      };
    case actionTypes.DELETE_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletePending: {
            ...state.ui.isDeletePending,
            [action.payload.userId]: true,
          },
          isDeleteSuccess: false,
        },
      };
    case actionTypes.DELETE_SUCCESS:
      const userId = action.payload.userId;
      const {[userId]: discard, ...newEntities} = state.entities;
      const deleteIndex = state.list.indexOf(userId);
      // console.log({userId}); // tslint:disable-line
      // console.log(state.list); // tslint:disable-line
      // console.log({deleteIndex}); // tslint:disable-line
      return {
        ...state,
        entities: newEntities,
        list: [
          ...state.list.slice(0, deleteIndex),
          ...state.list.slice(deleteIndex + 1),
        ],
        ui: {
          ...state.ui,
          isDeletePending: {
            ...state.ui.isDeletePending,
            [action.payload.userId]: false,
          },
          isDeleteSuccess: true,
        },
      };
    case actionTypes.DELETE_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletePending: {
            ...state.ui.isDeletePending,
            [action.payload.userId]: false,
          },
          isDeleteSuccess: false,
        },
      };
    case actionTypes.RESET_DELETE_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeleteSuccess: false,
        },
      };
    case actionTypes.FROM_ROUTE:
      return {
        ...state,
        ui: {
          ...state.ui,
          // fromDbRedirect: false
        },
      };
    case actionTypes.FROM_DB_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          // fromDbRedirect: false,
          // isFromDbFail: false,
          // isFromDbPending: true,
          // isFromDbSuccess: false
        },
      };
    case actionTypes.FROM_DB_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          // fromDbRedirect: false,
          // isFromDbFail: false,
          // isFromDbPending: false,
          // isFromDbSuccess: true
        },
      };
    case actionTypes.FROM_DB_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          //   fromDbError: action.payload.message,
          //   fromDbRedirect: action.payload.code === 301,
          //   isFromDbFail: true,
          //   isFromDbPending: false,
          //   isFromDbSuccess: false
        },
      };
    case actionTypes.FROM_DB_RESET_UI_ERROR:
      return {
        ...state,
        // ui: {
        //   ...state.ui,
        //   fromDbRedirect: false,
        //   isFromDbFail: false
        // }
      };
    case actionTypes.RESET:
      return {
        ...state,
        // newUser: {addPeopleModalIsOpen: false}
      };
    case actionTypes.VIEW_FROM_ROUTE:
      return {
        ...state,
        // ui: {
        //   ...state.ui
        //   // viewFromDbRedirect: false
        // },
      };
    case actionTypes.VIEW_FROM_DB_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          // isViewFromDbFail: false,
          // isViewFromDbPending: true,
          // isViewFromDbSuccess: false,
          // viewFromDbRedirect: false
        },
      };
    case actionTypes.VIEW_FROM_DB_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          // isViewFromDbFail: false,
          // isViewFromDbPending: false,
          // isViewFromDbSuccess: true,
          // viewFromDbRedirect: false
        },
      };
    case actionTypes.VIEW_FROM_DB_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          // isViewFromDbFail: true,
          // isViewFromDbPending: false,
          // isViewFromDbSuccess: false,
          // viewFromDbError: action.payload.message,
          // viewFromDbRedirect: action.payload.code === 301
        },
      };
    case actionTypes.VIEW_FROM_DB_RESET_UI_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          // fromDbRedirect: false,
          // isFromDbFail: false
        },
      };
    case actionTypes.VIEW_RESET:
      return {
        ...state,
        // newUser: {addPeopleModalIsOpen: false}
      };
    case actionTypes.UPLOAD_BULK_FILE_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUploadBulkFail: false,
          isUploadBulkPending: true,
          isUploadBulkSuccess: false,
        },
      };
    case actionTypes.UPLOAD_BULK_FILE_SUCCESS:
      return {
        ...state,
        filePath: action.payload.filePath,
        report: action.payload.report,
        ui: {
          ...state.ui,
          isUploadBulkFail: false,
          isUploadBulkPending: false,
          isUploadBulkSuccess: true,
        },
      };
    case actionTypes.UPLOAD_BULK_FILE_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUploadBulkFail: true,
          isUploadBulkPending: false,
          isUploadBulkSuccess: false,
          uploadBulkError:
            action.payload && action.payload.error.message
              ? action.payload.error.message
              : "Errore sconosciuto, riprova più tardi",
        },
      };
    case actionTypes.RESET_FILE_STATE:
      return {
        ...state,
        ui: {
          ...state.ui,
          acceptBulkError: undefined,
          isAcceptBulkFail: false,
          isAcceptBulkPending: false,
          isAcceptBulkSuccess: false,
          isUploadBulkFail: false,
          isUploadBulkPending: false,
          isUploadBulkSuccess: false,
          uploadBulkError: undefined,
        },
      };
    case actionTypes.ACCEPT_BULK_FILE_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isAcceptBulkFail: false,
          isAcceptBulkPending: true,
          isAcceptBulkSuccess: false,
        },
      };
    case actionTypes.ACCEPT_BULK_FILE_SUCCESS:
      return {
        ...state,
        report: action.payload.report,
        ui: {
          ...state.ui,
          isAcceptBulkFail: false,
          isAcceptBulkPending: false,
          isAcceptBulkSuccess: true,
        },
      };
    case actionTypes.ACCEPT_BULK_FILE_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          acceptBulkError:
            action.payload && action.payload.error.message
              ? action.payload.error.message
              : "Errore sconosciuto, riprova più tardi",
          isAcceptBulkFail: true,
          isAcceptBulkPending: false,
          isAcceptBulkSuccess: false,
        },
      };
    case userActionTypes.UPDATE_LOGGED_USER:
      if (!action.payload.user) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.user.id]: {
            ...(action.payload.user as any),
          },
        },
      };
    case actionTypes.RESET_PASSWORD_CONTRACTOR_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isResetPasswordContractorFail: false,
          isResetPasswordContractorPending: true,
          isResetPasswordContractorSuccess: false,
        },
      };
    case actionTypes.RESET_PASSWORD_CONTRACTOR_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isResetPasswordContractorFail: false,
          isResetPasswordContractorPending: false,
          isResetPasswordContractorSuccess: true,
        },
      };
    case actionTypes.RESET_PASSWORD_CONTRACTOR_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          resetPasswordContractorError:
            action.payload && action.payload.message
              ? action.payload.message
              : "Errore sconosciuto, riprova più tardi",

          isResetPasswordContractorFail: true,
          isResetPasswordContractorPending: false,
          isResetPasswordContractorSuccess: false,
        },
      };

    case actionTypes.GOT_COURSES_HISTORY:
      return {
        ...state,
        coursesHistory: action.payload.coursesHistory,
      };

    default:
      return state;
  }
};

export default reducer;
