import React from "react";
import IconBase from "./IconBase";

const IconPlus = (props: any) => (
  <IconBase {...props}>
    <path d="M29.71 12.57H19.43V2.3A2.29 2.29 0 0 0 17.14 0h-2.28a2.29 2.29 0 0 0-2.29 2.29v10.28H2.3A2.28 2.28 0 0 0 0 14.86v2.28a2.29 2.29 0 0 0 2.29 2.29h10.28V29.7a2.29 2.29 0 0 0 2.29 2.3h2.28a2.29 2.29 0 0 0 2.29-2.29V19.43h10.29A2.29 2.29 0 0 0 32 17.14v-2.28a2.29 2.29 0 0 0-2.29-2.29z" />
  </IconBase>
);

export default IconPlus;
