import {lazy} from "@loadable/component";
import IRoutes from "../types/IRoutes";

const ListPage = lazy(
  () => import(/* webpackChunkName: 'analisi' */ "./ListPage")
);
const NewPage = lazy(
  () => import(/* webpackChunkName: 'analisi-new' */ "./NewPage")
);
const ViewPage = lazy(
  () => import(/* webpackChunkName: 'analisi-view' */ "./ViewPage")
);

const routes: IRoutes = [
  {
    children: <ListPage />,
    name: "analisi",
    path: "/analisi",
    permission: "cap.read||cap.contractor-read",
  },
  {
    children: <NewPage />,
    name: "analisi-new",
    path: "/analisi/new/:capId",
    permission: "cap.manage",
  },
  {
    children: <NewPage />,
    name: "analisi-new",
    path: "/analisi/new",
    permission: "cap.manage",
  },
  {
    children: <ViewPage />,
    name: "analisi-view",
    path: "/analisi/view/:capId",
    permission: "cap.read||recommendations.read||cap.contractor-read",
  },
];

export default routes;
