import React, {VFC} from "react";
import {Container} from "reactstrap";
import {IconSpinner} from "../Icons";

export const PageLoading: VFC = () => (
  <Container className="main-center">
    <img
      src="/img/logo.svg"
      className="logo mx-auto mb-4 d-block"
      alt={`${process.env.REACT_APP_TITLE} logo`}
    />
    <p className="text-center">
      <IconSpinner className="icon-spin" size={1.25} /> Caricamento in corso...
    </p>
  </Container>
);
