import React from "react";
import {
  FormFeedback,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

const RenderField = ({
  input,
  meta: {touched, error, warning, dirty},
  prependAddOn,
  appendAddOn,
  helpText,
  manualWaring,
  ...props
}: any) => (
  <>
    <InputGroup>
      {prependAddOn && (
        <InputGroupAddon addonType="prepend">{prependAddOn}</InputGroupAddon>
      )}
      <Input
        {...(props.noValidate !== true && touched ? {invalid: !!error} : {})}
        {...(props.noValidate !== true && dirty ? {valid: !error} : {})}
        {...input}
        {...props}
      />
      {appendAddOn && (
        <InputGroupAddon addonType="append">{appendAddOn}</InputGroupAddon>
      )}
    </InputGroup>
    {error && touched && (
      <FormFeedback className="d-block">{error}</FormFeedback>
    )}
    {!error && touched && (warning || manualWaring) && (
      <FormText color="warning" className="d-block">
        {warning || manualWaring}
      </FormText>
    )}
    <FormText color="secondary" className="d-block">
      {helpText}
    </FormText>
  </>
);

export default RenderField;
