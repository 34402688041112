import React from "react";
import IconBase from "./IconBase";

const IconIdFront = (props: any) => (
  <IconBase {...props}>
    <path
      d="M29.333 1.778H2.667A2.667 2.667 0 0 0 0 4.444v.89h32v-.89a2.667 2.667 0 0 0-2.667-2.666zM0 24a2.667 2.667 0 0 0 2.667 2.667h26.666A2.667 2.667 0 0 0 32 24V7.111H0V24zm19.556-12.889c0-.244.2-.444.444-.444h8c.244 0 .444.2.444.444V12c0 .244-.2.444-.444.444h-8a.446.446 0 0 1-.444-.444v-.889zm0 3.556c0-.245.2-.445.444-.445h8c.244 0 .444.2.444.445v.889c0 .244-.2.444-.444.444h-8a.446.446 0 0 1-.444-.444v-.89zm0 3.555c0-.244.2-.444.444-.444h8c.244 0 .444.2.444.444v.89c0 .244-.2.444-.444.444h-8a.446.446 0 0 1-.444-.445v-.889zm-9.778-7.555a3.559 3.559 0 0 1 3.555 3.555 3.559 3.559 0 0 1-3.555 3.556 3.559 3.559 0 0 1-3.556-3.556 3.559 3.559 0 0 1 3.556-3.555zM3.728 22.01a3.56 3.56 0 0 1 3.383-2.455h.456a5.733 5.733 0 0 0 4.422 0h.455a3.56 3.56 0 0 1 3.384 2.455c.178.55-.29 1.1-.867 1.1H4.594c-.577 0-1.044-.555-.866-1.1z"
      transform="matrix(1 0 0 1 0 1.7775)"
    />
  </IconBase>
);

export default IconIdFront;
