import React from "react";
import IconBase from "./IconBase";

const IconDelivery = (props: any) => (
  <IconBase {...props}>
    <path d="M24.6 9.9l-1.7-5c-.3-1-1.2-1.6-2.3-1.6H4.9c-1 0-1.9.7-2.3 1.6L.1 12.5c-.1.2-.1.4-.1.7v13.2c0 1.3 1.1 2.4 2.4 2.4h20c5.3 0 9.6-4.3 9.6-9.6 0-4.5-3.1-8.3-7.4-9.3zm-11-5.1h7.1c.3 0 .6.2.8.5l1.4 4.3h-.5c-2.8 0-5.4 1.2-7.1 3.2h-1.7v-8zm-9.4.5c.1-.3.4-.5.7-.5H12v8H1.7l2.5-7.5zM2.4 27.2c-.4 0-.8-.4-.8-.8v-12h12.5c-.8 1.4-1.3 3.1-1.3 4.8 0 3.3 1.7 6.3 4.3 8H2.4zm20 0c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm3.2-11.1c-.2-.2-.4-.2-.6 0l-3.5 3.5-1.4-1.6c-.2-.2-.4-.2-.6 0l-.9 1c-.2.2-.2.4 0 .6l2.7 2.7c.2.2.4.2.6 0l4.7-4.7c.2-.2.2-.4 0-.6l-1-.9z" />
  </IconBase>
);

export default IconDelivery;
