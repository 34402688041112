import {connect} from "react-redux";
import {IRootState} from "../redux/reducers";
import {actions as userActions} from "../User/actions";
import {actions as usersActions} from "../Users/actions";
import {selectLoggedUser} from "../User/selectors";
import {getUser} from "../Users/selectors";
import {actions} from "./actions";
import {IInsertPhoneFormData} from "./InsertPhoneForm";
import RequestOTPModal from "./RequestOTPModal";
import {
  getAdditionalParams,
  getESign,
  getIsModalOpen,
  getIsUpdatingPhone,
  getOpenedTransaction,
  getPDFType,
  getReferenceId,
  getThunkActionStates,
} from "./selectors";
import {ISignFEADocRequestData, PDFTypes} from "./types";

const mapStateToProps = (state: IRootState) => ({
  additionalParams: getAdditionalParams(state),
  createFEATransactionStates: getThunkActionStates(
    state,
    "createFEATransaction"
  ),
  isModalOpen: getIsModalOpen(state),
  isProfileFail: state.user.isProfileFail,
  isProfilePending: state.user.isProfilePending,
  loggedUser: selectLoggedUser(state),
  openedTransaction: getESign(state, getOpenedTransaction(state)),
  pdfType: getPDFType(state),
  profileError: state.user.profileError,
  referenceId: getReferenceId(state),
  signFEADocStates: getThunkActionStates(state, "signFEADoc"),
  signingUser: getUser(state, state.eSign.additionalParams?.contractorId),
  isUpdatingPhone: getIsUpdatingPhone(state),
});

const mapDispatchToProps = {
  addPersonalPhone: (values: IInsertPhoneFormData) =>
    userActions.profile(values),
  addContractorPhone: (contractorId: string, values: IInsertPhoneFormData) =>
    usersActions.update(contractorId, {personalPhone: values.personalPhone}),
  cancelESign: () => actions.cancelESign(),
  createFEATransaction: (inPlaceOfData?: {
    contractorId: string;
    pdfType: PDFTypes;
    referenceId: string;
  }) => actions.createFEATransaction(inPlaceOfData),
  signFEADoc: (data: ISignFEADocRequestData) => actions.signFEADoc(data),
  openEditNumberForm: () => actions.openEditNumberForm(),
  closeEditNumberForm: () => actions.closeEditNumberForm(),
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestOTPModal);
