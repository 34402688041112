import {createSelector} from "@reduxjs/toolkit";
import {getCapsEntities} from "../../../Caps/selectors";
import {IRootState} from "../../../redux/reducers";
import {selectLoggedUser, getUserPermissions} from "../../../User/selectors";
import {selectors as entitySelectors} from "../entities";

export const selectChosenCap = (state: IRootState) =>
  state.beneMarketings.common.chosenCap;

export const selectTempCongruenceFilePath = (state: IRootState) =>
  state.beneMarketings.common.tempCongruenceFilePath;

export const selectESignId = (state: IRootState) =>
  state.beneMarketings.common.eSignId;

export const selectESignIdError = (state: IRootState) =>
  state.beneMarketings.common.eSignIdError;

export const selectListIds = (state: IRootState) =>
  state.beneMarketings.common.list;

export const selectList = createSelector(
  [entitySelectors.fSelectByIds, selectListIds],
  (fByIds, ids) => fByIds(ids)
);

export const selectChosenCapEntity = createSelector(
  [getCapsEntities, selectChosenCap],
  (caps, capId) => {
    return capId && caps[capId] ? caps[capId] : undefined;
  }
);

export const selectCanSeeChosenCap = createSelector(
  [selectChosenCapEntity, selectLoggedUser, getUserPermissions],
  (chosenCap, user, permissions) =>
    permissions.includes("bene.read") ||
    permissions.includes("caps.read") ||
    (permissions.includes("cap.read") &&
      !!chosenCap &&
      chosenCap.user === user!.id)
);

export const selectLastCreatedId = (state: IRootState) =>
  state.beneMarketings.common.lastCreatedId;

export const selectListLength = (state: IRootState) =>
  state.beneMarketings.common.listLength;

export const selectListParams = (state: IRootState) =>
  state.beneMarketings.common.listParams;

export const selectIsAdvisor = createSelector(
  [entitySelectors.selectById, selectLoggedUser],
  (beneMarketing, loggedUser) => {
    if (!beneMarketing || !loggedUser) {
      return false;
    }
    return beneMarketing.user === loggedUser.id;
  }
);

export const selectIsContractor = createSelector(
  [entitySelectors.selectById, selectLoggedUser],
  (beneMarketing, loggedUser) => {
    if (!beneMarketing || !loggedUser) {
      return false;
    }
    return beneMarketing.contractor === loggedUser.id;
  }
);
