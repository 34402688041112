import React from "react";
import IconBase from "./IconBase";

const IconWelfare = (props: any) => (
  <IconBase {...props}>
    <path d="M31.901 20.491c-.103-.142-.262-.226-.435-.226h-4.802V4.801c0-.296-.238-.533-.532-.533h-1.601V2.667c0-.295-.238-.533-.533-.533h-12.8C11.201.955 10.245 0 9.066 0 7.888 0 6.933.955 6.933 2.134h-1.6c-.294 0-.533.238-.533.533v17.601H.534c-.294 0-.534.238-.534.532 0 .091.022.181.066.259l5.867 10.666c.093.17.274.275.467.275h21.333c.229.006.434-.141.5-.357h.004l3.734-10.668a.539.539 0 0 0-.07-.484m-6.3-15.157V26.48l-1.07-1.947h.005V5.334h1.065zM9.067 1.066c.589 0 1.066.479 1.066 1.065H8c0-.586.479-1.065 1.067-1.065m-3.2 2.135h4.267v1.582c0 .283-.214.529-.498.55-.31.019-.568-.228-.568-.532v-.037c0-.272-.223-.496-.497-.496h-.072c-.275 0-.499.222-.499.496 0 .859.702 1.615 1.56 1.636.902.022 1.643-.7 1.643-1.6V3.2h12.266v19.4l-1.132-2.061a.531.531 0 0 0-.467-.275h-16V3.201h-.003zm.848 27.733l-5.279-9.6h20.116l5.28 9.6H6.715zm20.912-.772l-.96-1.741v-7.087h4.047l-3.087 8.828z" />
    <path d="M28.801 4.268c0-1.179-.954-2.134-2.134-2.134h-.532c-.296 0-.534.238-.534.533s.238.534.534.534h.532c.589 0 1.066.477 1.066 1.064V11.2c0 .295.238.532.535.532.291 0 .53-.237.53-.532l.003-6.932z" />
    <path d="M28.269 12.8c.293 0 .532.239.532.533a.5326.5326 0 1 1-1.065 0c-.003-.294.238-.533.533-.533" />
    <path d="M3.201 10.666c.294 0 .533.238.533.534v6.4c0 .295-.239.534-.533.534s-.534-.239-.534-.534v-6.4c0-.296.24-.534.534-.534" />
    <path d="M3.201 8.533c.294 0 .533.236.533.533 0 .295-.239.533-.533.533s-.534-.238-.534-.533.24-.533.534-.533" />
    <path d="M11.734 10.134h1.599v1.601c0 .293.239.533.535.533H16c.294 0 .534-.24.534-.533v-1.601h1.601c.294 0 .533-.239.533-.532V7.468a.532.532 0 0 0-.533-.532h-1.601V5.334c0-.295-.24-.533-.534-.533h-2.133c-.295 0-.535.238-.535.533v1.602h-1.599c-.295 0-.533.237-.533.532v2.134c.002.293.238.532.534.532M12.268 8h1.601c.293 0 .531-.238.531-.532V5.867h1.066v1.601c0 .294.24.532.534.532h1.6v1.066H16a.533.533 0 0 0-.534.533V11.2H14.4V9.6c0-.295-.239-.533-.533-.533h-1.601L12.268 8z" />
    <path d="M9.067 13.333c.294 0 .533.239.533.533 0 .295-.239.532-.533.532s-.533-.237-.533-.532c.001-.294.239-.533.533-.533" />
    <path d="M11.201 13.333h10.133c.294 0 .533.239.533.533a.532.532 0 0 1-.533.532H11.201a.532.532 0 0 1-.533-.532c-.001-.294.239-.533.533-.533" />
    <path d="M8 15.467h13.333c.294 0 .534.239.534.533s-.24.533-.534.533H8c-.294 0-.533-.239-.533-.533s.24-.533.533-.533" />
    <path d="M8 17.6h11.201c.294 0 .532.239.532.534 0 .294-.238.532-.532.532H8a.5326.5326 0 0 1-.533-.532c0-.295.24-.534.533-.534" />
    <path d="M21.813 4.801h.031c.295 0 .533.237.533.533 0 .295-.238.533-.533.533h-.031a.5335.5335 0 0 1-.533-.533c0-.296.241-.533.533-.533" />
    <path d="M21.829 6.934c.294 0 .533.238.533.533 0 .297-.239.533-.533.533s-.533-.236-.533-.533c0-.295.239-.533.533-.533" />
  </IconBase>
);

export default IconWelfare;
