import React from "react";
import IconBase from "./IconBase";

const IconComplete = (props: any) => (
  <IconBase {...props}>
    <path d="M32 12.48c0 1.47-1.3 2.74-2.8 2.74h-6.24a3.07 3.07 0 0 1-.4 4.43c.9 1.43.14 3.31-1.02 3.9C22.1 26.62 20.17 28 17 28l-1-.01c-3.87 0-4.8-1.94-7.73-2.4A1.5 1.5 0 0 1 7 24.11V13.4a3 3 0 0 1 1.78-2.74c1.8-.81 5.96-3.06 6.72-4.83C15.99 4.68 16.84 4 18 4a3 3 0 0 1 2.76 4.18 8.6 8.6 0 0 1-.89 1.56h9.33c1.47 0 2.8 1.28 2.8 2.74zM6 12.5v12c0 .83-.67 1.5-1.5 1.5h-3A1.5 1.5 0 0 1 0 24.5v-12c0-.83.67-1.5 1.5-1.5h3c.83 0 1.5.67 1.5 1.5zM4.25 23a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0z" />
  </IconBase>
);

export default IconComplete;
