import {Dispatch} from "redux";
import {Actions as CapsActions} from "../Caps/actions";
import IApiError from "../types/IApiError";
import {actions as usersActions} from "../Users/actions";
import {ThunkActionsNames} from "./reducers";
import service from "./service";
import {ITicketEntity} from "./types";

export enum ActionTypes {
  SET_IS_PENDING = "[Tickets] set the pending state of a thunk",
  SET_IS_SUCCESS = "[Tickets] set the success state of a thunk",
  SET_IS_FAIL = "[Tickets] set the fail state of a thunk",
  RESET_STATE = "[Tickets] reset the state of a thunk",
}

interface ISetIsPending {
  type: typeof ActionTypes.SET_IS_PENDING;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}
interface ISetIsSuccess {
  type: typeof ActionTypes.SET_IS_SUCCESS;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}
interface ISetIsFail {
  type: typeof ActionTypes.SET_IS_FAIL;
  error: IApiError;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}
interface IResetState {
  type: typeof ActionTypes.RESET_STATE;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}

export const actions = {
  setIsPending: (
    thunkActionsName: ThunkActionsNames,
    id?: string
  ): ISetIsPending => ({
    payload: {thunkActionsName, id},
    type: ActionTypes.SET_IS_PENDING,
  }),
  setIsSuccess: (
    thunkActionsName: ThunkActionsNames,
    id?: string
  ): ISetIsSuccess => ({
    payload: {thunkActionsName, id},
    type: ActionTypes.SET_IS_SUCCESS,
  }),
  setIsFail: (
    thunkActionsName: ThunkActionsNames,
    error: IApiError,
    id?: string
  ): ISetIsFail => ({
    payload: {thunkActionsName, id},
    error,
    type: ActionTypes.SET_IS_FAIL,
  }),
  resetState: (
    thunkActionsName: ThunkActionsNames,
    id?: string
  ): IResetState => ({
    payload: {thunkActionsName, id},
    type: ActionTypes.RESET_STATE,
  }),

  create: (
    ticket: Omit<ITicketEntity, "attachment" | "id"> & {attachment: File}
  ) => (dispatch: Dispatch<actions | CapsActions | usersActions>) => {
    dispatch(actions.setIsPending("create"));
    return service
      .create(ticket)
      .then((createdTicket) => {
        dispatch(actions.setIsSuccess("create"));
      })
      .catch((error) => {
        dispatch(actions.setIsFail("create", error));
      });
  },
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type actions = ISetIsPending | ISetIsSuccess | ISetIsFail | IResetState;
