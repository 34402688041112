import React from "react";
import "./IconBase.scss";

interface IIconBase {
  children: React.ReactNode;
  color?: "primary" | "secondary" | "success" | "info" | "warning" | "danger";
  id: string;
  position?: "topLeft" | "topRight" | "bottomRight" | "bottomLeft";
  size: number;
  title?: string;
  className?: string;
}

const IconBase = ({
  children,
  className,
  color,
  id,
  position,
  size,
  title,
  ...props
}: IIconBase) => {
  let iconClassName = `${className || ""} ${position ? position : "iconBase"}`;
  iconClassName += color ? ` ${color}` : "";

  return (
    <svg
      aria-labelledby={id}
      className={iconClassName}
      fill="currentColor"
      height="32"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 32 32"
      width="32"
      style={size ? {fontSize: `${size}em`} : undefined}
      {...props}
    >
      {title && <title id={id}>{title}</title>}
      {children}
    </svg>
  );
};

export default IconBase;
