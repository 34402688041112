import {createAsyncThunk} from "@reduxjs/toolkit";
import service from "../service";
import {actions} from "./slice";
import {ILoadAdvisorsParams} from "../types";

export const loadAnalytics = createAsyncThunk(
  "loadAnalytics",
  async (params: ILoadAdvisorsParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(actions.loadAnalyticsRequest(params));

      const tree = await service.loadAdvisorsTree(params);

      thunkAPI.dispatch(actions.loadAnalyticsSuccess(tree));
      thunkAPI.dispatch(actions.loadAdvisor(tree));

      return tree;
    } catch (e: any) {
      thunkAPI.dispatch(actions.loadAnalyticsError(e));

      return undefined;
    }
  }
);

export const loadRoots = createAsyncThunk(
  "loadAnalyticsRoots",
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(actions.loadRootsRequest());

      const roots = await service.loadRoots();
      thunkAPI.dispatch(actions.loadRootsSuccess(roots));

      return roots;
    } catch (e: any) {
      thunkAPI.dispatch(actions.loadRootsError(e));

      return undefined;
    }
  }
);
