import {combineReducers} from "redux";
import {
  reducer as commonReducer,
  actions as commonActions,
  selectors as commonSelectors,
} from "./slice/common";
import {
  reducer as entitiesReducer,
  actions as entitiesActions,
  selectors as entitiesSelectors,
} from "./slice/entities";
import {
  reducer as thunkStatesReducer,
  actions as thunkStatesActions,
  selectors as thunkStatesSelectors,
} from "./slice/thunkStates";

export const reducer = combineReducers({
  common: commonReducer,
  entities: entitiesReducer,
  thunkStates: thunkStatesReducer,
});
export const actions = {
  ...commonActions,
  ...entitiesActions,
  ...thunkStatesActions,
};
export const selectors = {
  ...commonSelectors,
  ...entitiesSelectors,
  ...thunkStatesSelectors,
};
