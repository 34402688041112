import React from "react";
import IconBase from "./IconBase";

const IconESignedDocument = (props: any) => (
  <IconBase {...props}>
    <path d="M16.3 22.7l-.9-2.6c-.2-.6-.8-1-1.4-1s-1.2.4-1.4 1l-1.1 3.4c-.1.3-.4.5-.7.5H10c-.6 0-1 .4-1 1s.4 1 1 1h.8c1.2 0 2.2-.7 2.6-1.8l.7-2 1.1 3.2c.1.4.5.7.9.7h.1c.4 0 .7-.2.9-.6l.5-1c.2-.3.5-.4.6-.4.1 0 .5 0 .6.4.3.9 1.2 1.5 2.2 1.5h1c.6 0 1-.4 1-1s-.4-1-1-1h-.9c-.3 0-.5-.1-.6-.4-.8-1.5-3-1.9-4.2-.9zM8.5 6h7c.3 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5h-7c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5zM16 9.5v-1c0-.3-.2-.5-.5-.5h-7c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5h7c.3 0 .5-.2.5-.5zm11.1-3.4L21.9.9c-.6-.6-1.3-.9-2.1-.9H7C5.3 0 4 1.4 4 3v26c0 1.7 1.3 3 3 3h18c1.7 0 3-1.3 3-3V8.2c0-.7-.3-1.5-.9-2.1zM20 2c.2 0 .3.1.5.3l5.2 5.2c.1.2.2.3.3.5h-6V2zm6 27c0 .5-.5 1-1 1H7c-.6 0-1-.5-1-1V3c0-.5.4-1 1-1h11v6.5c0 .8.7 1.5 1.5 1.5H26v19z" />
  </IconBase>
);

export default IconESignedDocument;
