import IApiError from "../../types/IApiError";
import {IAdvisorTree, ILoadAdvisorsParams, IRoots} from "../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {loadAnalytics, loadRoots} from "./thunks";
import {combineReducers} from "redux";

type ILoadTreeState = {
  advisorsTree?: IAdvisorTree;
  selectedAdvisor?: IAdvisorTree;
  loadAdvisorRequest?: ILoadAdvisorsParams;
  loadAdvisorError?: IApiError;
};

type ILoadRootsState = {
  roots?: IRoots[];
  isLoading?: boolean;
  loadRootsError?: IApiError;
};

const analyticsInitialState: ILoadTreeState = {};

const rootsInitialState: ILoadRootsState = {};

const loadTreeSlice = createSlice({
  name: "analytics/loadTree",
  initialState: analyticsInitialState,
  reducers: {
    loadAnalyticsRequest: (
      state,
      action: PayloadAction<ILoadAdvisorsParams>
    ) => ({
      loadAdvisorRequest: action.payload,
    }),
    loadAnalyticsError: (state, action: PayloadAction<IApiError>) => ({
      ...state,
      loadAdvisorRequest: undefined,
      loadAdvisorError: action.payload,
    }),
    loadAnalyticsSuccess: (state, action: PayloadAction<IAdvisorTree>) => ({
      advisorsTree: action.payload,
    }),
    loadAdvisor: (state, action: PayloadAction<IAdvisorTree>) => ({
      ...state,
      selectedAdvisor: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(loadAnalytics.fulfilled, (_state, _action) => {});
  },
});

const loadRootsSlice = createSlice({
  name: "analytics/loadRoots",
  initialState: rootsInitialState,
  reducers: {
    loadRootsRequest: (state, action: PayloadAction) => ({
      isLoading: true,
    }),
    loadRootsError: (state, action: PayloadAction<IApiError>) => ({
      ...state,
      isLoading: false,
      loadRootsError: action.payload,
    }),
    loadRootsSuccess: (state, action: PayloadAction<IRoots[]>) => ({
      isLoading: false,
      roots: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(loadRoots.fulfilled, (_state, _action) => {});
  },
});

export const reducer = combineReducers({
  tree: loadTreeSlice.reducer,
  roots: loadRootsSlice.reducer,
});
export const actions = {
  ...loadTreeSlice.actions,
  ...loadRootsSlice.actions,
  loadAnalytics,
  loadRoots,
};
