import React from "react";
import IconBase from "./IconBase";

const IconUnlock = (props: any) => (
  <IconBase {...props}>
    <path d="M23.52 1.386c-4.676 0-8.48 3.804-8.48 8.48v2.939H2.262A2.833 2.833 0 0 0 0 15.576v3.209a.564.564 0 1 0 1.13 0v-3.209c0-.936.761-1.696 1.696-1.696h15.831c.936 0 1.696.761 1.696 1.696v12.212c0 .935-.761 1.695-1.696 1.695H2.827a1.698 1.698 0 0 1-1.696-1.695v-4.467a.564.564 0 1 0-1.131 0v4.467a2.83 2.83 0 0 0 2.827 2.826h15.831a2.83 2.83 0 0 0 2.827-2.826V15.576a2.833 2.833 0 0 0-2.261-2.771V9.866c0-2.369 1.928-4.297 4.297-4.297s4.297 1.928 4.297 4.297v1.941a2.09 2.09 0 0 0 4.182 0V9.866c0-4.677-3.804-8.48-8.48-8.48m7.349 10.402a.963.963 0 0 1-.961.961.963.963 0 0 1-.961-.961V9.866c0-2.993-2.435-5.428-5.427-5.428s-5.428 2.435-5.428 5.428v2.939H16.17V9.866c0-4.053 3.297-7.35 7.35-7.35s7.35 3.297 7.35 7.35v1.922z" />
    <path d="M.565 21.597a.57.57 0 0 0 .565-.565.566.566 0 0 0-.965-.399.566.566 0 0 0 .4.964" />
    <path d="M10.742 27.562c.935 0 1.696-.761 1.696-1.696v-3.002a3.038 3.038 0 0 0 1.364-2.543 3.057 3.057 0 0 0-3.053-3.054 3.057 3.057 0 0 0-3.053 3.054c0 1.028.509 1.97 1.35 2.533v3.012c0 .935.761 1.696 1.696 1.696M8.827 20.32c0-1.061.862-1.923 1.922-1.923a1.924 1.924 0 0 1 .868 3.638.566.566 0 0 0-.31.504v3.326a.565.565 0 0 1-1.13 0v-3.333a.566.566 0 0 0-.306-.503 1.916 1.916 0 0 1-1.044-1.709" />
  </IconBase>
);

export default IconUnlock;
