import React from "react";
import IconBase from "./IconBase";

const IconAccountability = (props: any) => (
  <IconBase {...props}>
    <path d="M27.1 6.1L21.9.9c-.6-.6-1.3-.9-2.1-.9H7C5.3 0 4 1.3 4 3v26c0 1.7 1.3 3 3 3h18c1.7 0 3-1.3 3-3V8.2c0-.8-.3-1.5-.9-2.1zm-1.4 1.4c.1.2.2.3.3.5h-6V2c.2 0 .3.1.5.3l5.2 5.2zM25 30H7c-.6 0-1-.4-1-1V3c0-.6.4-1 1-1h11v6.5c0 .8.7 1.5 1.5 1.5H26v19c0 .6-.4 1-1 1zm-2.2-13.2l-8.4 8.4c-.3.3-.8.3-1.1 0L9.1 21c-.3-.3-.3-.8 0-1.1l.5-.5c.3-.3.8-.3 1.1 0l3.2 3.2 7.4-7.3c.3-.3.8-.3 1.1 0l.5.5c.2.2.2.7-.1 1z" />
  </IconBase>
);

export default IconAccountability;
