import React from "react";
import IconBase from "./IconBase";

const IconIdBack = (props: any) => (
  <IconBase {...props}>
    <path d="M29.333 3.555H2.667A2.667 2.667 0 0 0 0 6.221v.89h32v-.89a2.667 2.667 0 0 0-2.667-2.666zM0 25.777a2.667 2.667 0 0 0 2.667 2.668h26.666A2.667 2.667 0 0 0 32 25.777V8.888H0v16.89zm19.556-9.333c0-.245.2-.445.444-.445h8c.244 0 .444.2.444.445v.89c0 .243-.2.443-.444.443h-8a.446.446 0 0 1-.444-.444v-.89.001zm0 3.556c0-.245.2-.445.444-.445h8c.244 0 .444.2.444.445v.89c0 .244-.2.444-.444.444h-8a.446.446 0 0 1-.444-.446V20z" />
  </IconBase>
);

export default IconIdBack;
