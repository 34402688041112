import React from "react";
import IconBase from "./IconBase";

const IconClock = (props: any) => (
  <IconBase {...props}>
    <path d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32zm0 28.9a12.9 12.9 0 1 1 0-25.8 12.9 12.9 0 0 1 0 25.8zm3.99-6.73l-5.48-3.98a.78.78 0 0 1-.32-.63V6.96c0-.42.35-.77.78-.77h2.06c.43 0 .77.35.77.78v9.14l4.31 3.13c.35.26.42.74.17 1.09L21.07 22a.78.78 0 0 1-1.08.17z" />
  </IconBase>
);

export default IconClock;
