import {createAsyncThunk} from "@reduxjs/toolkit";
import {actions as capsActions} from "../../../Caps/actions";
import {createAsyncThunkAndReset} from "../../../helpers/createAsyncThunkAndReset";
import {actions as usersActions} from "../../../Users/actions";
import service from "../../service";
import {
  elementaryClassEntityUtility,
  IElementaryClassParams,
} from "../../types";
import {actions as entityActions} from "../entities";

export const create = createAsyncThunkAndReset(
  "elementaryClasses/create",
  async (
    elementaryClass: {
      cap: string;
      company: string;
    },
    thunkAPI
  ) => {
    const createdElementaryClass = await service.create(elementaryClass);
    const {
      result,
      entities: {users, elementaryClasses, caps},
    } = elementaryClassEntityUtility.normalizeEntity(createdElementaryClass);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(elementaryClasses ?? {}));

    return {elementaryClassId: result};
  }
);

export const get = createAsyncThunk(
  "elementaryClasses/get",
  async (id: string, thunkAPI) => {
    const elementaryClass = await service.read(id);
    const {
      result,
      entities: {users, elementaryClasses, caps},
    } = elementaryClassEntityUtility.normalizeEntity(elementaryClass);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(elementaryClasses ?? {}));

    return {elementaryClassesId: result};
  }
);

export const list = createAsyncThunk(
  "elementaryClasses/list",
  async (params: IElementaryClassParams, thunkAPI) => {
    const {numElementaryClasses, elementaryClassList} = await service.list(
      params
    );
    const {
      result,
      entities: {users, elementaryClasses, caps},
    } = elementaryClassEntityUtility.normalizeEntity(elementaryClassList);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(elementaryClasses ?? {}));

    return {elementaryClassesIds: result, numElementaryClasses, params};
  }
);

export const sendEmail = createAsyncThunk(
  "elementaryClasses/sendEmail",
  async (elementaryClassId: string, thunkAPI) => {
    const elementaryClass = await service.sendEmail(elementaryClassId);
    const {
      entities: {users, elementaryClasses, caps},
    } = elementaryClassEntityUtility.normalizeEntity(elementaryClass);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(elementaryClasses ?? {}));
  }
);
