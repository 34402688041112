import React from "react";
import IconBase from "./IconBase";

const IconUndo = (props: any) => (
  <IconBase {...props}>
    <path d="M15.97 0C11.69 0 7.81 1.7 4.95 4.43l-2.3-2.3A1.55 1.55 0 0 0 0 3.23v8.64c0 .86.7 1.55 1.55 1.55h8.65a1.55 1.55 0 0 0 1.1-2.64l-2.7-2.7a10.84 10.84 0 1 1 .26 16.07.77.77 0 0 0-1.05.04l-2.56 2.56c-.32.32-.3.83.03 1.13A16 16 0 1 0 15.98 0z" />
  </IconBase>
);

export default IconUndo;
