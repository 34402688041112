import React from "react";
import IconBase from "./IconBase";

const IconDanger = (props: any) => (
  <IconBase {...props}>
    <path d="M32 16c0 8.839-7.164 16-16 16-8.835 0-16-7.161-16-16C0 7.167 7.165 0 16 0c8.836 0 16 7.167 16 16zm-16 3.226a2.968 2.968 0 1 0 0 5.936 2.968 2.968 0 0 0 0-5.936zM13.183 8.559l.478 8.774c.023.41.362.731.773.731h3.132c.411 0 .75-.321.772-.731l.479-8.774a.774.774 0 0 0-.773-.817h-4.089a.773.773 0 0 0-.772.817z" />
  </IconBase>
);

export default IconDanger;
