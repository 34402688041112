import React from "react";
import IconBase from "./IconBase";

const IconEye = (props: any) => (
  <IconBase {...props}>
    <path d="M31.63 14.65A18.11 18.11 0 0 0 16 5.78C9.34 5.78 3.5 9.33.37 14.65c-.5.83-.5 1.87 0 2.7A18.11 18.11 0 0 0 16 26.22c6.66 0 12.5-3.55 15.63-8.87.5-.83.5-1.87 0-2.7zM16 23.55c-5.7 0-10.67-3.03-13.33-7.55a15.43 15.43 0 0 1 12-7.5 3.1 3.1 0 1 1-5.34 2.17A7.56 7.56 0 1 0 22 9.63 15.37 15.37 0 0 1 29.34 16 15.44 15.44 0 0 1 16 23.56z" />
  </IconBase>
);

export default IconEye;
