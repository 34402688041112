import React from "react";
import IconBase from "./IconBase";

const IconSendNotification = (props: any) => (
  <IconBase {...props}>
    <path d="M16 32c2.2 0 4-1.8 4-4h-8c0 2.2 1.8 4 4 4zm13.5-9.4C28.3 21.3 26 19.4 26 13c0-4.9-3.4-8.7-8-9.7V2c0-1.1-.9-2-2-2s-2 .9-2 2v1.3c-4.6 1-8 4.8-8 9.7 0 6.4-2.3 8.3-3.5 9.6-.3.4-.5.9-.5 1.4 0 1 .8 2 2 2h24c1.2 0 2-1 2-2 0-.5-.2-1-.5-1.4zM16 22c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm1.6-6.9c-.1.5-.5.9-1 .9h-1.2c-.5 0-.9-.4-1-.9l-.8-6c-.1-.6.4-1 .9-1.1h2.9c.6 0 1 .4 1 1v.1l-.8 6z" />
  </IconBase>
);

export default IconSendNotification;
