import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DeepReadonly} from "utility-types";
import {IElementaryClassParams} from "../types";
import * as commonSelectors from "./common/selectors";
import {create, get, list, sendEmail} from "./common/thunks";

type IState = DeepReadonly<{
  chosenCap?: string;
  lastCreatedId?: string;
  tempCongruenceFilePath?: string;
  eSignId?: string;
  eSignIdError?: string;
  list: string[];
  listLength: number;
  listParams: IElementaryClassParams;
}>;

const elementaryClassesInitialState: IState = {
  list: [],
  listLength: 0,
  listParams: {limit: 50, offset: 0, sort: "-date"},
};

const common = createSlice({
  name: "elementaryClasses/common",
  initialState: elementaryClassesInitialState,
  reducers: {
    choseCap: (state, action: PayloadAction<{capId: string | undefined}>) => {
      state.chosenCap = action.payload.capId;
    },
    congruenceFileCreated: (
      state,
      action: PayloadAction<{
        path: string | undefined;
        eSignId: string | undefined;
      }>
    ) => {
      state.tempCongruenceFilePath = action.payload.path;
      state.eSignId = action.payload.eSignId;
    },
    setEsignIdError: (
      state,
      action: PayloadAction<{message: string | undefined}>
    ) => {
      state.eSignIdError = action.payload.message;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.list = action.payload.elementaryClassesIds;
        state.listLength = action.payload.numElementaryClasses;
        state.listParams = action.payload.params;
      })
      .addCase(create.fulfilled, (state, action) => {
        state.lastCreatedId = action.payload.elementaryClassId;
      })
      .addCase(create.reset, (state, action) => {
        delete state.lastCreatedId;
      });
  },
});

export const selectors = commonSelectors;

export const actions = {...common.actions, create, get, list, sendEmail};
export const reducer = common.reducer;
