import {faSyncAlt} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/browser";
import React from "react";
import {ErrorInfo} from "react";
import {Link} from "react-router-dom";
import {Button, Col, Container, Row} from "reactstrap";

import {IconBack} from "../Icons";

interface IState {
  hasError: boolean;
  isChunkError: boolean;
}

class ErrorBoundaryPage extends React.Component<{}, IState> {
  public readonly state = {hasError: false, isChunkError: false};

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
      isChunkError: error.name === "ChunkLoadError",
    });
    if (process.env.NODE_ENV !== "development") {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key as "componentStack"]);
        });
        Sentry.captureException(error);
      });
    }
  }

  public resetError = () => {
    this.setState({hasError: false, isChunkError: false});
  };

  public render() {
    if (this.state.hasError) {
      return (
        <Container className="main-center">
          <img
            src="/img/logo.svg"
            className="logo mx-auto mb-4 d-block"
            alt={`${process.env.REACT_APP_TITLE} logo`}
          />
          <Row>
            {this.state.isChunkError ? (
              <Col className="text-center">
                <h3 className="mb-4">Errore connessione</h3>
                <p>
                  Impossibile caricare la pagina. Controlla la tua connessione
                  ad internet e riprova
                </p>
                <Button
                  color="light"
                  outline
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <FontAwesomeIcon icon={faSyncAlt} /> Ricarica la pagina
                </Button>
              </Col>
            ) : (
              <Col className="text-center">
                <h3 className="mb-4">Errore imprevisto</h3>
                <p>
                  <Link to="/" onClick={this.resetError}>
                    <Button color="light" outline>
                      <IconBack /> Torna alla pagina principale
                    </Button>
                  </Link>
                  {process.env.NODE_ENV !== "development" && (
                    <Button
                      color="light"
                      outline
                      onClick={Sentry.showReportDialog}
                      className="ml-2"
                    >
                      Lascia un feedback
                    </Button>
                  )}
                </p>
              </Col>
            )}
          </Row>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundaryPage;
