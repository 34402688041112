import {
  AnyAction,
  createSlice,
  Draft,
  isAllOf,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import {DeepReadonly} from "utility-types/dist/mapped-types";
import IApiError from "../types/IApiError";
import {IAppThunkActionStates} from "../types/thunk";
import {actionTypes as userActionTypes} from "../User/actions";
import {isReset} from "./createAsyncThunkAndReset";

type IState = DeepReadonly<{
  [key: string]: {[key: string]: IAppThunkActionStates};
}>;

export interface GenericThunkArg {
  thunkId?: string;
}
interface PayloadWithError {
  error?: IApiError;
}

const emptyThunkObject = {};

const isInNameSpace =
  (nameSpace: string) =>
  (action: AnyAction): action is AnyAction => {
    return action.type.startsWith(nameSpace + "/");
  };

interface ICreateThunkStatesSliceOptions {
  nameSpace: string;
  selectState: (state: any) => IState;
}

export const createThunkStatesSlice = ({
  nameSpace,
  selectState,
}: ICreateThunkStatesSliceOptions) => {
  const thunkStatesInitialState: IState = {};

  const initializeState = (state: Draft<IState>, name: string, key: string) => {
    if (!state[name]) {
      state[name] = {};
    }
    if (!state[name][key]) {
      state[name][key] = {};
    }
  };

  const thunkStates = createSlice({
    name: "thunkStates",
    initialState: thunkStatesInitialState as IState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(userActionTypes.LOGOUT_SUCCESS, () => {
          return thunkStatesInitialState;
        })
        .addMatcher(
          isAllOf(isInNameSpace(nameSpace), isPending),
          (state, action) => {
            const name = action.type.substring(0, action.type.length - 8);
            const key =
              (action.meta.arg as GenericThunkArg)?.thunkId ?? "DEFAULT";
            initializeState(state, name, key);

            state[name][key].isPending = true;
            state[name][key].isSuccess = false;
            state[name][key].isFail = false;
            state[name][key].error = undefined;
          }
        )
        .addMatcher(
          isAllOf(isInNameSpace(nameSpace), isFulfilled),
          (state, action) => {
            const name = action.type.substring(0, action.type.length - 10);
            const key =
              (action.meta.arg as GenericThunkArg)?.thunkId ?? "DEFAULT";
            initializeState(state, name, key);

            state[name][key].isPending = false;
            state[name][key].isSuccess = true;
            state[name][key].isFail = false;
            state[name][key].error = undefined;
          }
        )
        .addMatcher(
          isAllOf(
            isInNameSpace(nameSpace),
            isAnyOf(isRejectedWithValue, isRejected)
          ),
          (state, action) => {
            const name = action.type.substring(0, action.type.length - 9);
            const key =
              (action.meta.arg as GenericThunkArg)?.thunkId ?? "DEFAULT";
            initializeState(state, name, key);

            // Nel caso ti thunk rejectWithValue l'errore è sul payload
            // Se abbiamo l'error sul payload ha la precedenza
            const actualError =
              (action.payload as PayloadWithError)?.error ?? action.error;

            state[name][key].isPending = false;
            state[name][key].isSuccess = false;
            state[name][key].isFail = true;
            state[name][key].error =
              actualError.message ?? "Errore sconosciuto, riprova più tardi";
          }
        )
        .addMatcher(
          isAllOf(isInNameSpace(nameSpace), isReset),
          (state, action) => {
            const name = action.type.substring(0, action.type.length - 6);
            const key = action.meta.arg?.thunkId ?? "DEFAULT";
            initializeState(state, name, key);

            state[name][key].isPending = false;
            state[name][key].isSuccess = false;
            state[name][key].isFail = false;
            state[name][key].error = undefined;
          }
        );
    },
  });

  const selectThunkActionStates = createSelector(
    [
      selectState,
      (state: any, name: string) => name,
      (state: any, _: any, key: string = "DEFAULT") => key,
    ],
    (thunkActionsStates, thunkActionName, key): IAppThunkActionStates => {
      if (key === "ALL") {
        return thunkActionsStates[thunkActionName] ?? emptyThunkObject;
      }
      return thunkActionsStates[thunkActionName]?.[key] ?? emptyThunkObject;
    }
  );

  return {
    actions: thunkStates.actions,
    reducer: thunkStates.reducer,
    selectors: {selectThunkActionStates},
  };
};
