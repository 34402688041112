import React from "react";
import IconBase from "./IconBase";

const IconInfoCircleSolid = (props: any) => (
  <IconBase {...props}>
    <path d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32zm0 7.1a2.71 2.71 0 1 1 0 5.42 2.71 2.71 0 0 1 0-5.42zm3.61 16.38c0 .43-.34.78-.77.78h-5.68a.77.77 0 0 1-.77-.78v-1.54c0-.43.34-.78.77-.78h.77v-4.13h-.77a.77.77 0 0 1-.77-.77V14.7c0-.43.34-.77.77-.77h4.13c.43 0 .77.34.77.77v6.45h.78c.43 0 .77.35.77.78v1.54z" />
  </IconBase>
);

export default IconInfoCircleSolid;
