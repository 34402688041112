import React from "react";
import IconBase from "./IconBase";

const IconWarning = (props: any) => (
  <IconBase {...props}>
    <path d="M31.64 26.22c1.02 1.78-.26 4-2.31 4H2.67a2.67 2.67 0 0 1-2.31-4L13.69 3.11a2.67 2.67 0 0 1 4.62 0l13.33 23.11zM16 21.44a2.56 2.56 0 1 0 0 5.12 2.56 2.56 0 0 0 0-5.12zm-2.43-9.18l.42 7.56c.02.35.3.63.66.63h2.7c.35 0 .64-.28.66-.63l.42-7.56a.67.67 0 0 0-.67-.7h-3.52a.67.67 0 0 0-.67.7z" />
  </IconBase>
);

export default IconWarning;
