import {apiService} from "../App/service";
import {
  IElementaryClassDB,
  IElementaryClassEntity,
  IElementaryClassParams,
} from "./types";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}elementary-classes`;

const elementaryClassesService = {
  create(elementaryClasses: {cap: string; company: string}) {
    return apiService.post(apiUrl, elementaryClasses).then((responseJson) => {
      return responseJson.elementaryClass as IElementaryClassDB;
    });
  },

  read(id: string) {
    return apiService
      .get(`${apiUrl}/${id}`)
      .then(
        (responseJson) => responseJson.elementaryClass as IElementaryClassDB
      );
  },

  list(params: IElementaryClassParams) {
    return apiService.get(apiUrl, params).then((responseJson) => ({
      numElementaryClasses: responseJson.numElementaryClasses as number,
      elementaryClassList:
        responseJson.elementaryClasses as IElementaryClassDB[],
    }));
  },

  update(id: string, elementaryClasses: Partial<IElementaryClassEntity>) {
    return apiService
      .put(`${apiUrl}/${id}`, elementaryClasses)
      .then(
        (responseJson) => responseJson.elementaryClass as IElementaryClassDB
      );
  },

  delete(id: string) {
    return apiService.delete(`${apiUrl}/${id}`);
  },

  sendEmail(id: string) {
    return apiService
      .post(`${apiUrl}/${id}/send-email`, {})
      .then(
        (responseJson) => responseJson.elementaryClass as IElementaryClassDB
      );
  },
};

export default elementaryClassesService;
