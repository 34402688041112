import {faTriangleExclamation} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {formatWithMonthAndDay} from "../types/dates";
import {NEW_VERSION} from "./consts";

export const NewVersionModal: React.FC = (props) => {
  const [isOpen, setIsOpen] = React.useState(true);

  if (!NEW_VERSION) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      backdrop="static"
      keyboard={false}
      className="modal-form"
      centered
    >
      <ModalHeader>
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          size="3x"
          color="orange"
        />
        Importante
      </ModalHeader>
      <ModalBody>
        <p>
          Questa versione di Prevision Family può essere usata solo per
          consultare lo storico delle attività svolte fino a{" "}
          {formatWithMonthAndDay(NEW_VERSION.dateStop)}.
        </p>
        <p>
          A partire da {formatWithMonthAndDay(NEW_VERSION.dateStartNewVersion)}{" "}
          è disponibile la nuova piattaforma all'indirizzo{" "}
          <a href={NEW_VERSION.url}>{NEW_VERSION.url}</a>.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={() => setIsOpen(false)}>
          Voglio consultare lo storico
        </Button>
        <Button tag="a" href={NEW_VERSION.url} color="primary" outline>
          Voglio usare la nuova piattaforma
        </Button>
      </ModalFooter>
    </Modal>
  );
};
