import React from "react";
import IconBase from "./IconBase";

const IconBan = (props: any) => (
  <IconBase {...props}>
    <path d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32zm8.4 7.6a11.87 11.87 0 0 1 1.33 15.2L9.19 6.28a11.87 11.87 0 0 1 15.2 1.34zM7.6 24.4A11.88 11.88 0 0 1 6.28 9.2l16.54 16.53a11.87 11.87 0 0 1-15.2-1.34z" />
  </IconBase>
);

export default IconBan;
