import {createEntitySlice} from "../../helpers/createEntitySlice";
import {
  elementaryClassEntityUtility,
  EntityShape,
  IElementaryClass,
  IElementaryClassDB,
  IElementaryClassEntity,
} from "../types";

const entitySlice = createEntitySlice<
  IElementaryClassEntity,
  IElementaryClassDB,
  IElementaryClass,
  EntityShape
>({
  entityUtility: elementaryClassEntityUtility,
  nameSpace: "elementaryClasses/entities",
  selectState: (state: any) => state.elementaryClasses.entities,
});

export const {selectors, actions, reducer} = entitySlice;
