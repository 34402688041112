import React from "react";
import IconBase from "./IconBase";

const IconSearch = (props: any) => (
  <IconBase {...props}>
    <path d="M31.56 27.67l-6.23-6.23a1.5 1.5 0 0 0-1.06-.44h-1.02A13 13 0 1 0 21 23.25v1.02c0 .4.16.78.44 1.06l6.23 6.23c.58.59 1.53.59 2.11 0l1.77-1.77a1.5 1.5 0 0 0 .01-2.12zM13 21a8 8 0 1 1 0-16 8 8 0 0 1 0 16z" />
  </IconBase>
);

export default IconSearch;
