import React from "react";
import IconBase from "./IconBase";

const IconRefresh = (props: any) => (
  <IconBase {...props}>
    <path d="M23.4 8.08a10.85 10.85 0 0 0-17.9 5.26.78.78 0 0 1-.75.6h-3.7a.77.77 0 0 1-.76-.92 16 16 0 0 1 26.77-8.59l2.3-2.3A1.55 1.55 0 0 1 32 3.23v8.64c0 .86-.7 1.55-1.55 1.55H21.8a1.55 1.55 0 0 1-1.1-2.64l2.7-2.7zM1.55 18.58h8.65a1.55 1.55 0 0 1 1.1 2.64l-2.7 2.7a10.85 10.85 0 0 0 17.91-5.26c.09-.35.4-.6.75-.6h3.7c.48 0 .85.44.76.92a16 16 0 0 1-26.77 8.59l-2.3 2.3c-.98.98-2.65.29-2.65-1.1v-8.64c0-.86.7-1.55 1.55-1.55z" />
  </IconBase>
);

export default IconRefresh;
