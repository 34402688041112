import {createEntitySlice} from "../../helpers/createEntitySlice";
import {
  beneMarketingEntityUtility,
  EntityShape,
  IBeneMarketing,
  IBeneMarketingDB,
  IBeneMarketingEntity,
} from "../types";

const entitySlice = createEntitySlice<
  IBeneMarketingEntity,
  IBeneMarketingDB,
  IBeneMarketing,
  EntityShape
>({
  entityUtility: beneMarketingEntityUtility,
  nameSpace: "beneMarketings/entities",
  selectState: (state: any) => state.beneMarketings.entities,
});

export const {selectors, actions, reducer} = entitySlice;
