import {faTools} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {CSSProperties} from "react";
import {Col, Container, Row} from "reactstrap";

const MaintenancePage = () => (
  <Container className="main-center">
    <img
      src="/img/logo.svg"
      className="logo mx-auto mb-4 d-block"
      alt={`${process.env.REACT_APP_TITLE} logo`}
    />
    <Row>
      <Col className="text-center">
        <h3 className="mb-4">
          <FontAwesomeIcon
            icon={faTools}
            style={{"--fa-secondary-opacity": 0.6} as CSSProperties}
          />{" "}
          Sito in manutenzione
        </h3>
        <p>
          Il sito non è attualmente raggiungibile per un intervento di
          manutenzione straordinaria.
        </p>
      </Col>
    </Row>
  </Container>
);

export default MaintenancePage;
