import {VFC} from "react";
import Navbar from "./Navbar";
import "./Header.scss";

const Header: VFC = () => (
  <header id="app-header">
    <Navbar />
  </header>
);

export default Header;
