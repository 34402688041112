import React from "react";
import IconBase from "./IconBase";

const IconFillIn = (props: any) => (
  <IconBase {...props}>
    <path d="M22.37 6.4l5 5c.22.22.22.56 0 .78L15.25 24.3l-5.15.57c-.69.08-1.27-.5-1.2-1.2l.58-5.15L21.6 6.4a.54.54 0 0 1 .77 0zm9-1.28l-2.71-2.7a2.17 2.17 0 0 0-3.07 0l-1.97 1.96a.54.54 0 0 0 0 .77l5.01 5c.21.22.56.22.77 0l1.97-1.96c.84-.85.84-2.22 0-3.06zm-10.04 15.9v5.65H3.56V8.9h12.76c.18 0 .35-.08.48-.2l2.22-2.22a.67.67 0 0 0-.47-1.14H2.67A2.67 2.67 0 0 0 0 8v19.55c0 1.48 1.2 2.67 2.67 2.67h19.55c1.48 0 2.67-1.2 2.67-2.67V18.8c0-.6-.72-.9-1.14-.48l-2.22 2.23a.7.7 0 0 0-.2.47z" />
  </IconBase>
);

export default IconFillIn;
