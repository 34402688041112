import {DeepReadonly} from "utility-types";
import {IAppThunkActionStates} from "../types/thunk";
import {
  actions as userActions,
  actionTypes as userActionTypes,
} from "../User/actions";
import {Actions, ActionTypes} from "./actions";
import {IESignsEntities, PDFTypes} from "./types";

export type ThunkActionsNames = "createFEATransaction" | "signFEADoc";

export type IESignState = DeepReadonly<{
  additionalParams?: {[key: string]: string};
  entities: IESignsEntities;
  isModalOpen: boolean;
  isUpdatingPhone?: boolean;
  openedTransaction?: string;
  pdfType?: PDFTypes;
  referenceId?: string;
  thunkActionsStates: {[key in ThunkActionsNames]: IAppThunkActionStates};
}>;

const eSignInitialState = {
  entities: {},
  isModalOpen: false,
  thunkActionsStates: {createFEATransaction: {}, signFEADoc: {}},
};

export const reducer = (
  state: IESignState = eSignInitialState,
  action: Actions | userActions
): IESignState => {
  switch (action.type) {
    case userActionTypes.LOGOUT_SUCCESS:
      return {
        ...eSignInitialState,
      };
    case ActionTypes.MERGE_ITEMS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.eSigns,
        },
      };

    case ActionTypes.OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        pdfType: action.payload.pdfType,
        referenceId: action.payload.referenceId,
        additionalParams: action.payload.additionalParams,
      };
    case ActionTypes.CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        pdfType: undefined,
        referenceId: undefined,
        additionalParams: undefined,
        isUpdatingPhone: false,
      };

    case ActionTypes.OPEN_EDIT_NUMBER:
      return {
        ...state,
        isUpdatingPhone: true,
      };
    case ActionTypes.CLOSE_EDIT_NUMBER:
      return {
        ...state,
        isUpdatingPhone: false,
      };

    case ActionTypes.CREATE_FEA_TRANSACTION_REQUEST:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          createFEATransaction: {
            error: undefined,
            isFail: false,
            isPending: true,
            isSuccess: false,
          },
        },
      };
    case ActionTypes.CREATE_FEA_TRANSACTION_SUCCESS:
      return {
        ...state,
        openedTransaction: action.payload.eSignId,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          createFEATransaction: {
            error: undefined,
            isFail: false,
            isPending: false,
            isSuccess: true,
          },
        },
      };
    case ActionTypes.CREATE_FEA_TRANSACTION_FAIL:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          createFEATransaction: {
            error:
              action.payload && action.payload.message
                ? action.payload.message
                : "Errore sconosciuto, riprova più tardi",
            isFail: true,
            isPending: false,
            isSuccess: false,
          },
        },
      };
    case ActionTypes.CREATE_FEA_TRANSACTION_RESET:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          createFEATransaction: {
            error: undefined,
            isFail: false,
            isPending: false,
            isSuccess: false,
          },
        },
      };

    case ActionTypes.SIGN_FEA_DOC_REQUEST:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          signFEADoc: {
            error: undefined,
            isFail: false,
            isPending: true,
            isSuccess: false,
          },
        },
      };
    case ActionTypes.SIGN_FEA_DOC_SUCCESS:
      return {
        ...state,
        isModalOpen: false,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          signFEADoc: {
            error: undefined,
            isFail: false,
            isPending: false,
            isSuccess: true,
          },
        },
      };
    case ActionTypes.SIGN_FEA_DOC_FAIL:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          signFEADoc: {
            error:
              action.payload && action.payload.message
                ? action.payload.message
                : "Errore sconosciuto, riprova più tardi",
            isFail: true,
            isPending: false,
            isSuccess: false,
          },
        },
      };
    case ActionTypes.SIGN_FEA_DOC_RESET:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          signFEADoc: {
            error: undefined,
            isFail: false,
            isPending: false,
            isSuccess: false,
          },
        },
      };

    default:
      return {...state};
  }
};
