import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {ICourse} from "../Users/types";

const baseApiUrl = `${process.env.REACT_APP_API_BASE_URL}`;

type ServerResponse<T extends {}> = T &
  ({status: "ok"} | {status: "ko"; message?: string});

enum Tags {
  CoursesHistory = "coursesHistory",
}

export const api = createApi({
  reducerPath: "api",
  tagTypes: Object.values(Tags),
  baseQuery: fetchBaseQuery({baseUrl: baseApiUrl, credentials: "include"}),
  endpoints: (builder) => ({
    getCourseHistory: builder.query<ICourse[], string>({
      query: (userId) => `users/${userId}/courses-history`,
      transformResponse: (data: ServerResponse<{coursesHistory: ICourse[]}>) =>
        data.coursesHistory,
      providesTags: () => [Tags.CoursesHistory],
    }),
  }),
});
