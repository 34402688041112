import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSpring, animated} from "react-spring";
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import {IRootState} from "../redux/reducers";
import {IAppThunkActionStates} from "../types/thunk";
import {actions} from "./actions";
import "./NewTicketModal.scss";
import NewTicketForm from "./NewTicketModal/NewTicketForm";
import {getThunkActionStates} from "./selectors";
import useMeasure from "react-use-measure";
import {ResizeObserver} from "@juggle/resize-observer";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
}

const NewTicketModal = ({isOpen, toggle}: IProps) => {
  const dispatch = useDispatch();
  const createStates = useSelector<IRootState, IAppThunkActionStates>((state) =>
    getThunkActionStates(state, "create")
  );

  const resetCreateState = useCallback(() => {
    dispatch(actions.resetState("create"));
  }, [dispatch]);

  const [ref, {height}] = useMeasure({polyfill: ResizeObserver});
  const props = useSpring({height});

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="new-ticket"
      onClosed={resetCreateState}
    >
      <ModalHeader toggle={toggle}>
        Segnalazione di problemi tecnici o di errori applicativi
      </ModalHeader>
      <animated.div style={{overflow: "hidden", ...props}}>
        <div ref={ref}>
          {createStates.isSuccess ? (
            <ModalBody>
              <Alert color="success" className="mb-0">
                La tua segnalazione è stata correttamente inviata. Verrà presa
                in carico a breve. Potresti essere ricontattato via email in
                caso sia necessario avere maggiori informazioni.
              </Alert>
            </ModalBody>
          ) : (
            <NewTicketForm toggle={toggle} />
          )}
        </div>
      </animated.div>
    </Modal>
  );
};

export default NewTicketModal;
