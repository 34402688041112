import React from "react";
import IconBase from "./IconBase";

const IconFiscalCode = (props: any) => (
  <IconBase {...props}>
    <path d="M0 25.8c0 1.5 1.2 2.7 2.7 2.7h26.7c1.5 0 2.7-1.2 2.7-2.7V16H0v9.8zM10.7 22c0-.4.3-.7.7-.7H19c.4 0 .7.3.7.7v2.2c0 .4-.3.7-.7.7h-7.6c-.4 0-.7-.3-.7-.7V22zm-7.1 0c0-.4.3-.7.7-.7h4c.4 0 .7.3.7.7v2.2c0 .4-.3.7-.7.7h-4c-.4 0-.7-.3-.7-.7V22zM32 6.2v2.7H0V6.2c0-1.5 1.2-2.7 2.7-2.7h26.7c1.4.1 2.6 1.3 2.6 2.7z" />
  </IconBase>
);

export default IconFiscalCode;
