import {apiService} from "../App/service";
import {
  IBeneMarketingDB,
  IBeneMarketingEntity,
  IBeneMarketingParams,
} from "./types";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}bene-marketing-proposals`;

const beneMarketingService = {
  create(
    beneMarketing: Partial<IBeneMarketingEntity> & {
      dateEffect: string;
    }
  ) {
    return apiService.post(apiUrl, beneMarketing).then((responseJson) => {
      return responseJson.beneMarketing as IBeneMarketingDB;
    });
  },

  read(id: string) {
    return apiService
      .get(`${apiUrl}/${id}`)
      .then((responseJson) => responseJson.beneMarketing as IBeneMarketingDB);
  },

  list(params: IBeneMarketingParams) {
    return apiService.get(apiUrl, params).then((responseJson) => ({
      numBeneMarketings: responseJson.numBenesMarketing as number,
      beneMarketingsList: responseJson.benesMarketing as IBeneMarketingDB[],
    }));
  },

  update(id: string, beneMarketing: Partial<IBeneMarketingEntity>) {
    return apiService
      .put(`${apiUrl}/${id}`, beneMarketing)
      .then((responseJson) => responseJson.beneMarketing as IBeneMarketingDB);
  },

  delete(id: string) {
    return apiService.delete(`${apiUrl}/${id}`);
  },

  send(
    id: string
    // beneMarketing: Pick<IBeneMarketingEntity, "jsonProduct"> & {h
    //   dateEffect: string;
    // }
  ) {
    return apiService
      .post(`${apiUrl}/${id}/send-marketing`, {}) // , {...beneMarketingMarketing})
      .then((responseJson) => responseJson.beneMarketing as IBeneMarketingDB);
  },

  sendEmail(id: string) {
    return apiService
      .post(`${apiUrl}/${id}/send-email-marketing`, {})
      .then((responseJson) => responseJson.beneMarketing as IBeneMarketingDB);
  },
};

export default beneMarketingService;
