import React, {Suspense} from "react";
import {Outlet} from "react-router";
import Footer from "./Footer";
import {PageLoading} from "./PageLoading";

export const NoMenuLayout: React.FC = ({children}) => (
  <>
    <div id="main" className="main auto-margin-4 pt-4">
      <Suspense fallback={<PageLoading />}>
        <Outlet />
      </Suspense>
    </div>
    <Footer />
  </>
);
