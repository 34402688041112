import {createSelector} from "@reduxjs/toolkit";
import {getCapsEntities} from "../../../Caps/selectors";
import {IRootState} from "../../../redux/reducers";
import {selectLoggedUser, getUserPermissions} from "../../../User/selectors";
import {selectors as entitySelectors} from "../entities";

export const selectChosenCap = (state: IRootState) =>
  state.elementaryClasses.common.chosenCap;

export const selectTempCongruenceFilePath = (state: IRootState) =>
  state.elementaryClasses.common.tempCongruenceFilePath;

export const selectESignId = (state: IRootState) =>
  state.elementaryClasses.common.eSignId;

export const selectESignIdError = (state: IRootState) =>
  state.elementaryClasses.common.eSignIdError;

export const selectListIds = (state: IRootState) =>
  state.elementaryClasses.common.list;

export const selectList = createSelector(
  [entitySelectors.fSelectByIds, selectListIds],
  (fByIds, ids) => fByIds(ids)
);

export const selectChosenCapEntity = createSelector(
  [getCapsEntities, selectChosenCap],
  (caps, capId) => {
    return capId && caps[capId] ? caps[capId] : undefined;
  }
);

export const selectCanSeeChosenCap = createSelector(
  [selectChosenCapEntity, selectLoggedUser, getUserPermissions],
  (chosenCap, user, permissions) =>
    permissions.includes("elementary_class.read") ||
    permissions.includes("caps.read") ||
    (permissions.includes("cap.read") &&
      !!chosenCap &&
      chosenCap.user === user!.id)
);

export const selectLastCreatedId = (state: IRootState) =>
  state.elementaryClasses.common.lastCreatedId;

export const selectListLength = (state: IRootState) =>
  state.elementaryClasses.common.listLength;

export const selectListParams = (state: IRootState) =>
  state.elementaryClasses.common.listParams;

export const selectIsAdvisor = createSelector(
  [entitySelectors.selectById, selectLoggedUser],
  (elementaryClass, loggedUser) => {
    if (!elementaryClass || !loggedUser) {
      return false;
    }
    return elementaryClass.user === loggedUser.id;
  }
);
export const selectIsContractor = createSelector(
  [entitySelectors.selectById, selectLoggedUser],
  (elementaryClass, loggedUser) => {
    if (!elementaryClass || !loggedUser) {
      return false;
    }
    return elementaryClass.contractor === loggedUser.id;
  }
);
