import "moment/locale/it";
import React, {StrictMode} from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import store from "../redux/store";
import "./App.scss";
import ErrorBoundaryPage from "./ErrorBoundaryPage";
import RouterComponent from "./RouterComponent";
import ScrollToTop from "./ScrollToTop";

const PrevisionApp: React.FC = () => {
  return (
    <StrictMode>
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ScrollToTop>
            <ErrorBoundaryPage>
              <RouterComponent />
            </ErrorBoundaryPage>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    </StrictMode>
  );
};

export default PrevisionApp;
