import React from "react";
import IconBase from "./IconBase";

const IconTimesCircle = (props: any) => (
  <IconBase {...props}>
    <path d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32zm7.84 20.2c.3.3.3.8 0 1.1l-2.55 2.55c-.3.3-.8.3-1.1 0L16 19.6l-4.2 4.23c-.3.3-.8.3-1.1 0L8.16 21.3c-.3-.3-.3-.8 0-1.1L12.38 16l-4.24-4.2c-.3-.3-.3-.8 0-1.1l2.56-2.55c.3-.3.8-.3 1.1 0L16 12.39l4.2-4.24c.3-.3.8-.3 1.1 0l2.55 2.56c.3.3.3.8 0 1.1L19.61 16l4.23 4.2z" />
  </IconBase>
);

export default IconTimesCircle;
