import React from "react";
import IconBase from "./IconBase";

const IconSend = (props: any) => (
  <IconBase {...props}>
    <path d="M31.58 11.64l-8 7.55c-.84.8-2.25.2-2.25-.97v-4c-8.03.06-11.42 1.95-9.15 9.52.25.84-.71 1.48-1.39.97-2.17-1.65-4.12-4.8-4.12-7.97 0-8 6.53-9.58 14.66-9.63v-4a1.34 1.34 0 0 1 2.25-.97l8 7.56c.56.52.56 1.4 0 1.94zm-10.25 11.2v3.83H3.56V8.89h2.83c.18 0 .35-.08.48-.2.83-.87 1.79-1.56 2.83-2.1.62-.32.39-1.26-.31-1.26H2.67A2.66 2.66 0 0 0 0 8v19.56c0 1.47 1.2 2.66 2.67 2.66h19.55c1.48 0 2.67-1.2 2.67-2.66v-4.94A.67.67 0 0 0 24 22c-.6.22-1.26.28-1.9.2a.67.67 0 0 0-.77.65z" />
  </IconBase>
);

export default IconSend;
