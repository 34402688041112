import React from "react";
import IconBase from "./IconBase";

const IconLogin = (props: any) => (
  <IconBase {...props}>
    <path d="M26 24h-5.25a.75.75 0 01-.75-.75v-2.5a.75.75 0 01.75-.75H26a2 2 0 002-2V6a2 2 0 00-2-2h-5.25a.75.75 0 01-.75-.75V.75a.75.75 0 01.75-.75H26a6 6 0 016 6v12a6 6 0 01-6 6zm-2.94-12.56L12.56.94A1.5 1.5 0 0010 2v6H1.5A1.5 1.5 0 000 9.5v6A1.5 1.5 0 001.5 17H10v6a1.5 1.5 0 002.56 1.06l10.5-10.5a1.51 1.51 0 000-2.12z" />{" "}
  </IconBase>
);

export default IconLogin;
