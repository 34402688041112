import React from "react";
import IconBase from "./IconBase";

const IconCheckCircle = (props: any) => (
  <IconBase {...props}>
    <path d="M32 16a16 16 0 1 1-32 0 16 16 0 0 1 32 0zm-17.85 8.47L26.02 12.6c.4-.4.4-1.05 0-1.46l-1.46-1.46c-.4-.4-1.06-.4-1.46 0l-9.68 9.69-4.52-4.53c-.4-.4-1.06-.4-1.46 0L5.98 16.3c-.4.4-.4 1.06 0 1.46l6.7 6.71c.41.4 1.07.4 1.47 0z" />
  </IconBase>
);

export default IconCheckCircle;
