import React from "react";
import IconBase from "./IconBase";

const IconEmployment = (props: any) => (
  <IconBase {...props}>
    <path d="M30.55 17.45c-.44 0-.73.3-.73.73v9.46c0 .43-.3.72-.73.72H2.9c-.44 0-.73-.29-.73-.72v-9.46c0-.43-.29-.72-.73-.72-.43 0-.72.29-.72.72v9.46c0 1.23.94 2.18 2.18 2.18h26.18c1.24 0 2.18-.95 2.18-2.18v-9.46c0-.43-.29-.73-.72-.73" />
    <path d="M29.82 7.27H2.18C.94 7.27 0 8.22 0 9.45v4.66c0 1.02.73 1.89 1.67 2.1l11.42 2.55v2.33c0 .44.3.73.73.73h4.36c.44 0 .73-.3.73-.73v-2.33l11.42-2.54A2.18 2.18 0 0 0 32 14.1V9.45c0-1.23-.95-2.18-2.18-2.18m-12.37 13.1h-2.9v-2.92h2.9v2.91zm13.1-6.26a.7.7 0 0 1-.59.73L18.91 17.3v-.58c0-.44-.3-.73-.73-.73h-4.36c-.44 0-.73.3-.73.73v.58L2.04 14.84a.7.7 0 0 1-.59-.73V9.45c0-.43.3-.72.73-.72h27.64c.44 0 .73.29.73.72v4.66z" />
    <path d="M10.9 5.82c.45 0 .74-.3.74-.73v-.73c0-.43.29-.73.73-.73h7.27c.43 0 .72.3.72.73v.73c0 .44.3.73.73.73s.73-.3.73-.73v-.73c0-1.23-.94-2.18-2.18-2.18h-7.27c-1.24 0-2.19.95-2.19 2.18v.73c0 .44.3.73.73.73" />
  </IconBase>
);

export default IconEmployment;
