export const naturalNormalizer = (value: string) => {
  return numberNormalizer(value, {decimal: false, negative: false});
};

export const integerNormalizer = (value: string) => {
  return numberNormalizer(value, {decimal: false, negative: true});
};

export const floatNormalizer = (value: string) => {
  return numberNormalizer(value, {decimal: true, negative: true});
};

export const positiveFloatNormalizer = (value: string) => {
  return numberNormalizer(value, {decimal: true, negative: false});
};

export const numberNormalizer = (
  value: string,
  {decimal = true, negative = true}
) => {
  if (!value) {
    return "";
  }

  // eslint-disable-next-line no-useless-escape
  value = value.replace(/[^\d\.,-]/g, ""); // Rimuove tutti i caratteri strani
  if (negative) {
    value = value.replace(/(?!^)-/g, ""); // Rimuove tutti i "-" che non sono all'inizio
  } else {
    value = value.replace(/-/g, ""); // Rimuove tutti i "-"
  }
  value = value.replace(/,/g, "."); // Sostituisce tutti i punti con delle virgole
  if (decimal) {
    value = value.replace(".", "%FD%"); // Mette un placeholder al posto del primo punto
  }
  value = value
    .replace(/\./g, "") // Rimuove tutte le virgole
    .replace("%FD%", "."); // Rimette il punto al posto del placeholder

  return value;
};

export const emailNormalizer = (code: string) =>
  code.toLowerCase().replace(/\s/g, "");

export const fiscalCodeNormalize = (code: string) =>
  code.toUpperCase().replace(/\s/g, "");

export const ucWordsNormalizer = (code: string) => {
  return code.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const ucArubaNormalizer = (text: string) => {
  return text
    .replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .replace(/[’`]/, "'");
};

export const accentToApostrophe = (string: string): string => {
  return string
    .replaceAll("à", "a'")
    .replaceAll("è", "e'")
    .replaceAll("é", "e'")
    .replaceAll("ì", "i'")
    .replaceAll("ò", "o'")
    .replaceAll("ù", "u'");
};
