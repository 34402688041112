import React from "react";
import IconBase from "./IconBase";

const IconBulb = (props: any) => (
  <IconBase {...props}>
    <path d="M21 26.75v1.75c0 .65-.42 1.2-1 1.41v.59c0 .83-.67 1.5-1.5 1.5h-5a1.5 1.5 0 0 1-1.5-1.5v-.59c-.58-.2-1-.76-1-1.41v-1.75c0-.41.34-.75.75-.75h8.5c.41 0 .75.34.75.75zM12.06 24a1.5 1.5 0 0 1-1.4-.96C8.23 16.72 5 17.36 5 11a11 11 0 0 1 22 0c0 6.36-3.23 5.72-5.66 12.04a1.5 1.5 0 0 1-1.4.96h-7.88zM11 11a5 5 0 0 1 5-5 1 1 0 1 0 0-2 7 7 0 0 0-7 7 1 1 0 0 0 2 0z" />
  </IconBase>
);

export default IconBulb;
