import {lazy} from "@loadable/component";
import IRoutes from "../types/IRoutes";

const List = lazy(() => import(/* webpackChunkName: 'proposte' */ "./List"));
const New = lazy(() => import(/* webpackChunkName: 'proposte-new' */ "./New"));
const View = lazy(
  () => import(/* webpackChunkName: 'proposte-view' */ "./View")
);

const routes: IRoutes = [
  {
    children: <List />,
    name: "proposte",
    path: "/proposte",
    permission: "proposal.read||proposal.contractor-read",
    // Ulteriore controllo nel componente
  },
  {
    children: <New />,
    name: "proposte-new",
    path: "/proposte/new",
    permission: "proposal.manage",
    company: "99",
  },
  {
    children: <View />,
    name: "proposte-view",
    path: "/proposte/:id",
    permission: "proposal.read||proposal.contractor-read",
  },
];

export default routes;
