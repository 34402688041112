import React from "react";
import IconBase from "./IconBase";

const IconEvaluate = (props: any) => (
  <IconBase {...props}>
    <path d="M32 16a16 16 0 1 1-32 0 16 16 0 0 1 32 0zM16.43 5.3c-3.52 0-5.76 1.47-7.52 4.1-.23.34-.15.8.17 1.05l2.24 1.7c.34.25.82.2 1.08-.14 1.15-1.46 1.94-2.3 3.7-2.3 1.31 0 2.94.84 2.94 2.12 0 .97-.8 1.46-2.1 2.2-1.51.84-3.52 1.9-3.52 4.55v.26c0 .43.34.77.77.77h3.62c.42 0 .77-.34.77-.77v-.09c0-1.83 5.37-1.9 5.37-6.88 0-3.74-3.88-6.58-7.52-6.58zM16 21.28a2.97 2.97 0 1 0 0 5.94 2.97 2.97 0 0 0 0-5.94z" />
  </IconBase>
);

export default IconEvaluate;
