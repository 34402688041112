import React from "react";
import IconBase from "./IconBase";

const IconTargets = (props: any) => (
  <IconBase {...props}>
    <path d="M29.32 26.95l-5.98-6.91a2.38 2.38 0 0 0-3.72.13l-.63.83-1.08-1.44a.76.76 0 0 0-1.22.91l1.68 2.25c.1.12.21.21.35.27.33.12.69 0 .88-.26l1.24-1.64a.86.86 0 0 1 1.35-.05l5.84 6.74c.91 1.06.17 2.7-1.23 2.7H5.16a1.63 1.63 0 0 1-1.42-2.43l7.35-13.01c.15-.26.4-.42.7-.44.3-.02.57.1.75.34l2.24 3a.76.76 0 0 0 1.22-.92l-2.24-2.99c-.3-.4-.7-.68-1.14-.83V8.04H20a.76.76 0 0 0 .63-1.18L19.3 4.84l1.35-2.02c.16-.24.17-.54.04-.78s-.4-.41-.67-.41h-7.39V.76a.76.76 0 1 0-1.53 0V13.2c-.55.18-1.03.56-1.32 1.09L2.32 27.45A3.05 3.05 0 0 0 4.98 32h22.04a3.05 3.05 0 0 0 2.3-5.05m-16.7-23.8h5.96l-.84 1.26a.76.76 0 0 0 0 .85l.84 1.26h-5.96V3.15z" />
  </IconBase>
);

export default IconTargets;
