import {createEntitySlice} from "../../helpers/createEntitySlice";
import {
  beneProposalEntityUtility,
  EntityShape,
  IBeneProposal,
  IBeneProposalDB,
  IBeneProposalEntity,
} from "../types";

const entitySlice = createEntitySlice<
  IBeneProposalEntity,
  IBeneProposalDB,
  IBeneProposal,
  EntityShape
>({
  entityUtility: beneProposalEntityUtility,
  nameSpace: "beneProposals/entities",
  selectState: (state: any) => state.beneProposals.entities,
});

export const {selectors, actions, reducer} = entitySlice;
