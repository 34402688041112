import {createSelector} from "reselect";
import {IRootState} from "../redux/reducers";
import {ThunkActionsNames} from "./reducers";
import {hydrateUser} from "./types";

const emptyThunkObject = {};

export const getUsersEntities = (state: IRootState) => state.users.entities;

export const getThunkActionsStates = (state: IRootState) =>
  state.users.thunkActionsStates;
export const getThunkActionStates = createSelector(
  [
    getThunkActionsStates,
    (state: IRootState, name: ThunkActionsNames) => name,
    (state: IRootState, _: any, key: string = "DEFAULT") => key,
  ],
  (thunkActionsStates, thunkActionName, key) => {
    if (key === "ALL") {
      return thunkActionsStates[thunkActionName] ?? emptyThunkObject;
    }
    return thunkActionsStates[thunkActionName]?.[key] ?? emptyThunkObject;
  }
);

export const getParamsUserId = (
  state: IRootState,
  userId: string | undefined
) => userId;
export const getParamsUserIds = (state: IRootState, ids: string[]) => ids;

export const getUser = createSelector(
  [getUsersEntities, getParamsUserId],
  (users, userId) => {
    return !!userId && !!users[userId]
      ? hydrateUser({
          entities: {users},
          result: userId,
        })
      : undefined;
  }
);

export const getUsers = createSelector(
  [getUsersEntities, getParamsUserIds],
  (users, userIds) => {
    return hydrateUser({
      entities: {users},
      result: userIds,
    });
  }
);

export const selectUsersUi = (state: IRootState) => state.users.ui;

export const selectCoursesHistory = (state: IRootState) =>
  state.users.coursesHistory;
