import React from "react";
import IconBase from "./IconBase";

const IconCsv = (props: any) => (
  <IconBase {...props}>
    <path d="M18 8.5V0H5.5C4.67 0 4 .67 4 1.5v29c0 .83.67 1.5 1.5 1.5h21c.83 0 1.5-.67 1.5-1.5V10h-8.5A1.5 1.5 0 0 1 18 8.5zm-6 9a.5.5 0 0 1-.5.5H11a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.5c.27 0 .5.23.5.5v1a.5.5 0 0 1-.5.5H11a3 3 0 0 1-3-3v-2a3 3 0 0 1 3-3h.5c.27 0 .5.23.5.5v1zm2.77 6.5H14a.5.5 0 0 1-.5-.5v-1c0-.27.23-.5.5-.5h.77c.37 0 .64-.22.64-.41 0-.08-.04-.17-.13-.25l-1.37-1.17a2.34 2.34 0 0 1-.82-1.76c0-1.32 1.18-2.41 2.64-2.41h.77c.27 0 .5.23.5.5v1a.5.5 0 0 1-.5.5h-.77c-.37 0-.64.22-.64.41 0 .09.04.17.13.25l1.37 1.17c.52.45.82 1.1.82 1.76 0 1.32-1.18 2.41-2.64 2.41zM20 16.5v1.3c0 1.26.36 2.51 1 3.56.64-1.05 1-2.3 1-3.56v-1.3c0-.27.23-.5.5-.5h1c.27 0 .5.23.5.5v1.3a8.6 8.6 0 0 1-2.27 5.88 1 1 0 0 1-1.46 0A8.6 8.6 0 0 1 18 17.8v-1.3c0-.27.23-.5.5-.5h1c.27 0 .5.23.5.5zm7.56-9.94L21.45.44A1.5 1.5 0 0 0 20.38 0H20v8h8v-.38c0-.4-.16-.78-.44-1.06z" />
  </IconBase>
);

export default IconCsv;
