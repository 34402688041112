import React from "react";
import IconBase from "./IconBase";

const IconCalculate = (props: any) => (
  <IconBase {...props}>
    <path d="M2 29V3a3 3 0 0 1 3-3h22a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3zm24-17.75v-6.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v6.5c0 .41.34.75.75.75h18.5c.41 0 .75-.34.75-.75zm-16 8v-2.5a.75.75 0 0 0-.75-.75h-2.5a.75.75 0 0 0-.75.75v2.5c0 .41.34.75.75.75h2.5c.41 0 .75-.34.75-.75zm16 8v-10.5a.75.75 0 0 0-.75-.75h-2.5a.75.75 0 0 0-.75.75v10.5c0 .41.34.75.75.75h2.5c.41 0 .75-.34.75-.75zm-16 0v-2.5a.75.75 0 0 0-.75-.75h-2.5a.75.75 0 0 0-.75.75v2.5c0 .41.34.75.75.75h2.5c.41 0 .75-.34.75-.75zm8-8v-2.5a.75.75 0 0 0-.75-.75h-2.5a.75.75 0 0 0-.75.75v2.5c0 .41.34.75.75.75h2.5c.41 0 .75-.34.75-.75zm0 8v-2.5a.75.75 0 0 0-.75-.75h-2.5a.75.75 0 0 0-.75.75v2.5c0 .41.34.75.75.75h2.5c.41 0 .75-.34.75-.75z" />
  </IconBase>
);

export default IconCalculate;
