import React from "react";
import IconBase from "./IconBase";

const IconESign = (props: any) => (
  <IconBase {...props}>
    <path d="M12.1 25.3c-.1-.3-.4-.3-.5-.3-.1 0-.4 0-.6.3l-.4.9c-.4.7-1.4.6-1.6-.1l-1-2.8-.6 1.7c-.3 1-1.2 1.6-2.3 1.6h-.7c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h.7c.3 0 .5-.2.6-.4l1-3c.2-.5.7-.9 1.3-.9s1.1.4 1.3.9l.8 2.3c1.1-.9 3-.5 3.7.8.1.2.3.3.5.3v-4.6l7.1-7.1v-2.6h-7.6c-.7 0-1.3-.6-1.3-1.3V1.8H1.3C.6 1.8 0 2.4 0 3.1v25.8c0 .7.6 1.3 1.3 1.3H20c.7 0 1.3-.6 1.3-1.3v-2.2h-7.1c-.9-.1-1.7-.6-2.1-1.4zm9.2-16.7c0-.3-.1-.7-.4-.9l-5.4-5.4c-.2-.2-.6-.4-.9-.4h-.3V9h7.1v-.4zM16 21.1v3.8h3.8l9-9-3.8-3.8-9 9zm15.6-10l-1.8-1.8c-.6-.6-1.4-.6-2 0l-1.5 1.5 3.8 3.8 1.5-1.5c.5-.6.5-1.5 0-2z" />
  </IconBase>
);

export default IconESign;
