import {apiService} from "../App/service";
import {
  IBeneProposalDB,
  IBeneProposalEntity,
  IBeneProposalParams,
} from "./types";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}bene-proposals`;

const beneProposalService = {
  create(
    beneProposal: Partial<IBeneProposalEntity> & {
      dateEffect: string;
    }
  ) {
    return apiService.post(apiUrl, beneProposal).then((responseJson) => {
      return responseJson.bene as IBeneProposalDB;
    });
  },

  read(id: string) {
    return apiService
      .get(`${apiUrl}/${id}`)
      .then((responseJson) => responseJson.bene as IBeneProposalDB);
  },

  list(params: IBeneProposalParams) {
    return apiService.get(apiUrl, params).then((responseJson) => ({
      numBeneProposals: responseJson.numBenes as number,
      beneProposalsList: responseJson.benes as IBeneProposalDB[],
    }));
  },

  update(id: string, beneProposal: Partial<IBeneProposalEntity>) {
    return apiService
      .put(`${apiUrl}/${id}`, beneProposal)
      .then((responseJson) => responseJson.bene as IBeneProposalDB);
  },

  delete(id: string) {
    return apiService.delete(`${apiUrl}/${id}`);
  },

  send(
    id: string
    // beneProposal: Pick<IBeneProposalEntity, "jsonProduct"> & {
    //   dateEffect: string;
    // }
  ) {
    return apiService
      .post(`${apiUrl}/${id}/send`, {}) // , {...beneProposal})
      .then((responseJson) => responseJson.bene as IBeneProposalDB);
  },

  sendEmail(id: string) {
    return apiService
      .post(`${apiUrl}/${id}/send-email`, {})
      .then((responseJson) => responseJson.bene as IBeneProposalDB);
  },
};

export default beneProposalService;
