import {normalize, NormalizedSchema, schema} from "normalizr";

// v2.6.0 usiamo ReturnType<typeof beneProposalDeserialize> se non escono altri problemi
// export type Item<T> = {[K in keyof T]: T[K] extends {id: any} ? string : T[K]};

interface IEntityUtilityOptions<E, H> {
  deserialize: (entity: E) => H;
  serialize: (hydrated: H) => E;
  schema: schema.Entity<E>;
}
type EntityNormalizer<DB, NES> = {
  (databaseObject: DB): NormalizedSchema<NES, string>;
  (databaseObject: DB[]): NormalizedSchema<NES, string[]>;
};
export interface IEntityUtility<E, DB, H, NES> {
  normalizeEntity: EntityNormalizer<DB, NES>;
  deserialize: (entity: E) => H;
  serialize: (hydrated: H) => E;
}

export function entityUtilityFactory<E, DB, H, NES extends {}>({
  deserialize,
  serialize,
  schema,
}: IEntityUtilityOptions<E, H>): IEntityUtility<E, DB, H, NES> {
  function normalizeEntity(databaseObject: DB): NormalizedSchema<NES, string>;
  function normalizeEntity(
    databaseObject: DB[]
  ): NormalizedSchema<NES, string[]>;
  function normalizeEntity(databaseObject: DB | DB[]) {
    if (Array.isArray(databaseObject)) {
      return normalize(databaseObject, [schema]);
    } else {
      return normalize(databaseObject, schema);
    }
  }

  return {
    normalizeEntity,
    deserialize,
    serialize,
  };
}
