import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Button, Col, Container, Row} from "reactstrap";
import {IconBack, IconLogin, IconWarning} from "../Icons";
import {actions} from "./actions";

import "./NotAuthorizedPage.scss";

interface IDispatchProps {
  logout: () => Promise<void>;
}
type IProps = IDispatchProps;

const mapDispatchToProps = {
  logout: () => {
    return actions.logout();
  },
};

const NotAuthorizedPage: React.FC<IProps> = ({logout}) => {
  const switchAccount = async () => {
    await logout();
  };
  return (
    <Container className="main-center">
      <img
        src="/img/logo.svg"
        className="logo mx-auto mb-4 d-block"
        alt={`${process.env.REACT_APP_TITLE} logo`}
      />
      <Row>
        <Col className="text-center">
          <h3 className="mb-4">
            <IconWarning /> Accesso negato
          </h3>
          <p>Non disponi dei privilegi per visualizzare questa pagina</p>
          <p>
            <Link to="/">
              <Button color="light" outline>
                <IconBack /> Torna alla pagina principale
              </Button>
            </Link>
            <Button
              color="light"
              outline
              className="ml-2"
              onClick={switchAccount}
            >
              <IconLogin /> Accedi con un altro account
            </Button>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default connect(undefined, mapDispatchToProps)(NotAuthorizedPage);
