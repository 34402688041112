import React from "react";
import IconBase from "./IconBase";

const IconRetirement = (props: any) => (
  <IconBase {...props}>
    <path d="M31.397 27.172l-7.398-7.4V3.331A2 2 0 0 0 22 1.332H2a2 2 0 0 0-2 1.999v25.335a2 2 0 0 0 2 2h20a2 2 0 0 0 1.999-2v-3.107l4.51 4.508a2.038 2.038 0 0 0 2.89 0c.801-.799.8-2.094-.002-2.895m-8.731 1.494a.665.665 0 0 1-.666.664H2a.664.664 0 0 1-.667-.664V3.331c0-.37.297-.667.667-.667h20c.367 0 .666.298.666.667v15.107l-2-2V4.664a.666.666 0 0 0-.667-.665H3.781a.667.667 0 0 0 0 1.334h15.552v9.918l-3.702-1.847a.659.659 0 0 0-.698.063l-.002-.003-1.893 1.419-1.064-3.734a.669.669 0 0 0-1.113-.287l-1.803 1.805h-5.06c-.369 0-.667.3-.667.67v13.997a.67.67 0 0 0 .668.666h16a.669.669 0 0 0 .667-.67v-5.105l2 2.002v4.439zm-5.2-11.895l-.644-1.286 1.282.644-.638.642zm1.867 4.12v5.773H4.666V13.997h4.667a.672.672 0 0 0 .472-.192l1.199-1.199 1.021 3.576c.021.077.06.151.106.219a.668.668 0 0 0 .935.131l2.026-1.518 1.594 3.188.002-.004a.59.59 0 0 0 .125.174l2.52 2.519zm-1.107-2.993l1.006-1.008 8.811 8.807-1.009 1.006-8.808-8.805zm12.231 11.225a.713.713 0 0 1-1.006 0l-1.478-1.477 1.008-1.006 1.476 1.477a.711.711 0 0 1 0 1.006" />
  </IconBase>
);

export default IconRetirement;
