import {apiService} from "../App/service";
import {ITicketDB, ITicketEntity} from "./types";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}tickets`;

const proposalService = {
  create(
    ticket: Omit<ITicketEntity, "attachment" | "id"> & {attachment: File}
  ) {
    return apiService
      .post(apiUrl, ticket)
      .then((responseJson) => responseJson.ticket as ITicketDB);
  },
};

export default proposalService;
