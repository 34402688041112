import React from "react";
import {Alert, Button, FormGroup, Label} from "reactstrap";
import {Field, Form, InjectedFormProps, reduxForm} from "redux-form";
import {integerNormalizer} from "../helpers/normalizers";
import RenderField from "../helpers/RenderField";
import {IconSpinner} from "../Icons";
import {IUser} from "../Users/types";

export interface IInsertPhoneFormData {
  personalPhone?: string;
}
interface IInsertPhoneFormErrors {
  personalPhone?: string;
}

interface IOwnProps {
  addPersonalPhone: (values: IInsertPhoneFormData) => Promise<void>;
  addContractorPhone: (
    contractorId: string,
    values: IInsertPhoneFormData
  ) => Promise<void>;
  cancelESign: () => void;
  closeEditNumberForm: () => void;
  forContractor: boolean;
  isProfileFail?: boolean;
  isProfilePending?: boolean;
  profileError?: string;
  signingUser: IUser;
}

type IProps = IOwnProps & InjectedFormProps<IInsertPhoneFormData, IOwnProps>;

const validate = (values: IInsertPhoneFormData) => {
  const errors: IInsertPhoneFormErrors = {};
  if (!values.personalPhone) {
    errors.personalPhone = "Inserisci un numero di cellulare";
  }
  return errors;
};

const InsertPhoneForm: React.FC<IProps> = ({
  addContractorPhone,
  addPersonalPhone,
  cancelESign,
  closeEditNumberForm,
  forContractor,
  handleSubmit,
  isProfileFail,
  isProfilePending,
  profileError,
  signingUser,
}) => {
  const insertPhoneSubmit = async (values: IInsertPhoneFormData) => {
    if (forContractor) {
      await addContractorPhone(signingUser.id, values);
    } else {
      await addPersonalPhone(values);
    }
    closeEditNumberForm();
  };

  return (
    <Form onSubmit={handleSubmit(insertPhoneSubmit)}>
      {forContractor ? (
        <h3>Inserisci il numero di cellulare del tuo cliente</h3>
      ) : (
        <h3>Inserisci il tuo numero di cellulare</h3>
      )}
      <p>in questo modo potremo inviare l'OTP per firmare il documento</p>
      <FormGroup>
        <Label for="personalPhone">Numero di cellulare</Label>
        <Field
          id="personalPhone"
          name="personalPhone"
          component={RenderField}
          type="text"
          placeholder="Numero di cellulare"
          normalize={integerNormalizer}
        />
      </FormGroup>
      {isProfileFail && <Alert color="danger">{profileError}</Alert>}
      <Button color="primary" outline type="submit">
        {isProfilePending && <IconSpinner className="icon-spin mr-2" />}Conferma
      </Button>{" "}
      <Button color="secondary" outline type="button" onClick={cancelESign}>
        Annulla
      </Button>
    </Form>
  );
};

export default reduxForm<IInsertPhoneFormData, IOwnProps>({
  form: "insertPhoneForm",
  validate,
})(InsertPhoneForm);
