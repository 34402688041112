import {apiService} from "../App/service";
import {
  IProposalDB,
  IProposalEntity,
  IProposalProductDocumentsDB,
} from "./types";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}proposals`;
const proposalProductDocumentsApiUrl = `${process.env.REACT_APP_API_BASE_URL}proposal-product-documents`;

const proposalService = {
  create(proposal: Partial<IProposalEntity>) {
    return apiService
      .post(apiUrl, proposal)
      .then((responseJson) => responseJson.proposal as IProposalDB);
  },

  read(id: string) {
    return apiService
      .get(`${apiUrl}/${id}`)
      .then((responseJson) => responseJson.proposal as IProposalDB);
  },

  list() {
    return apiService.get(apiUrl).then((responseJson) => ({
      numProposals: responseJson.numProposals as number,
      proposalsList: responseJson.proposals as IProposalDB[],
    }));
  },

  update(id: string, proposal: Partial<IProposalEntity>) {
    return apiService
      .put(`${apiUrl}/${id}`, proposal)
      .then((responseJson) => responseJson.proposal as IProposalDB);
  },

  delete(id: string) {
    return apiService.delete(`${apiUrl}/${id}`);
  },

  duplicate(id: string) {
    return apiService
      .post(`${apiUrl}/${id}/duplicate`, {})
      .then((responseJson) => ({
        oldProposal: responseJson.proposal_archived,
        newProposal: responseJson.proposal,
      }));
  },

  uploadDocument(id: string, filePdf: File) {
    return apiService
      .post(`${apiUrl}/${id}/upload-proposal-pdf`, {filePdf})
      .then((responseJson) => responseJson.proposal as IProposalDB);
  },

  deleteDocument(id: string, fileName: string) {
    return apiService
      .delete(`${apiUrl}/${id}/delete-file?fileName=${fileName}`)
      .then((responseJson) => responseJson.proposal as IProposalDB);
  },

  proposalProductDocumentsList() {
    return apiService
      .get(proposalProductDocumentsApiUrl)
      .then(
        (responseJson) =>
          responseJson.proposalProductDocuments as IProposalProductDocumentsDB[]
      );
  },

  sendDarta(id: string) {
    return apiService
      .get(`${apiUrl}/${id}/send-darta`)
      .then((responseJson) => responseJson.proposal as IProposalDB);
  },

  sendNotification(id: string) {
    return apiService.get(`${apiUrl}/${id}/proposal-notification`);
  },
};

export default proposalService;
