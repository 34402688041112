import React from "react";
import IconBase from "./IconBase";

const IconSpinner = (props: any) => (
  <IconBase {...props}>
    <path d="M18.58 2.58a2.58 2.58 0 1 1-5.16 0 2.58 2.58 0 0 1 5.16 0zM16 26.84A2.58 2.58 0 1 0 16 32a2.58 2.58 0 0 0 0-5.16zM32 16a2.58 2.58 0 1 0-5.16 0A2.58 2.58 0 0 0 32 16zM5.16 16A2.58 2.58 0 1 0 0 16a2.58 2.58 0 0 0 5.16 0zM6.51 3.93a2.58 2.58 0 1 0 0 5.16 2.58 2.58 0 0 0 0-5.16zM25.5 22.91a2.58 2.58 0 1 0 0 5.16 2.58 2.58 0 0 0 0-5.16zm-18.98 0a2.58 2.58 0 1 0 0 5.16 2.58 2.58 0 0 0 0-5.16z" />
  </IconBase>
);

export default IconSpinner;
