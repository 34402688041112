import React from "react";
import IconBase from "./IconBase";

const IconBack = (props: any) => (
  <IconBase {...props}>
    <path d="M18.4 29.5l-1.6 1.6a1.7 1.7 0 0 1-2.41 0L.5 17.2a1.7 1.7 0 0 1 0-2.42L14.4.91a1.7 1.7 0 0 1 2.42 0l1.59 1.58c.67.68.66 1.79-.03 2.45l-8.61 8.2H30.3c.95 0 1.71.77 1.71 1.72v2.28c0 .95-.76 1.72-1.71 1.72H9.76l8.6 8.2c.7.66.72 1.77.04 2.45z" />
  </IconBase>
);

export default IconBack;
