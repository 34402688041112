import React from "react";
import IconBase from "./IconBase";

const IconOverdraft = (props: any) => (
  <IconBase {...props}>
    <path d="M15.068 4.253a.665.665 0 0 0-.663-.664H9.257a.664.664 0 1 0 0 1.329h5.148a.663.663 0 0 0 .663-.665" />
    <path d="M18.162 3.784a.658.658 0 0 0-.466-.195.67.67 0 0 0-.669.664.665.665 0 1 0 1.135-.469" />
    <path d="M10.742 6.512a.666.666 0 0 0 0 1.33h1.088a.665.665 0 1 0 0-1.33h-1.088z" />
    <path d="M14.403 7.842h3.292c.364 0 .659-.297.659-.664s-.297-.666-.659-.666h-3.292a.665.665 0 0 0 0 1.33" />
    <path d="M7.491 1.328H25.72a2.759 2.759 0 0 0-.414 1.462v8.706a.663.663 0 1 0 1.326 0v-2.06h3.588a.664.664 0 0 0 .666-.666V2.79A2.788 2.788 0 0 0 28.097 0H7.491a.665.665 0 0 0 0 1.328M29.56 2.79v5.319h-2.928V2.79c0-.805.66-1.462 1.465-1.462s1.463.657 1.463 1.462" />
    <path d="M10.855 1.328V0H7.491a2.79 2.79 0 0 0-2.789 2.79v4.216a.665.665 0 1 0 1.327 0V2.79c0-.81.655-1.462 1.462-1.462h3.364z" />
    <path d="M30.162 25.059l-4.342-4.344a7.64 7.64 0 0 0 .782-3.382c0-4.252-3.459-7.708-7.711-7.708-4.25 0-7.71 3.456-7.71 7.708 0 .972.183 1.903.512 2.759l.621 1.26a7.709 7.709 0 0 0 9.946 2.916l3.045 3.043v1.897a1.464 1.464 0 0 1-2.928 0v-.536c0-.021 0-.039-.004-.064.004-.02.004-.038.004-.063v-1.463a.663.663 0 0 0-.662-.664H6.03v-14.6a.663.663 0 1 0-1.328 0v14.599H1.777a.665.665 0 0 0-.665.665v2.126A2.794 2.794 0 0 0 3.902 32h19.941a2.793 2.793 0 0 0 2.789-2.792v-.569a2.479 2.479 0 0 0 1.734.698h.013a2.52 2.52 0 0 0 1.771-.735c.978-.983.988-2.573.012-3.543M3.902 30.67a1.464 1.464 0 0 1-1.461-1.462v-1.461h18.608v.798c0 .023.003.043.003.063 0 .025-.003.045-.003.064v.536c0 .536.153 1.037.416 1.462H3.902zm14.991-6.957a6.387 6.387 0 0 1-5.604-3.334l-.541-1.338a6.29 6.29 0 0 1-.235-1.708 6.388 6.388 0 0 1 6.38-6.382 6.39 6.39 0 0 1 6.382 6.382 6.387 6.387 0 0 1-6.382 6.38M29.21 27.66a1.175 1.175 0 0 1-.838.348h-.006c-.313 0-.602-.119-.821-.336l-4.11-4.111a7.674 7.674 0 0 0 1.677-1.674l4.114 4.109a1.184 1.184 0 0 1-.016 1.664" />
    <path d="M18.229 21.541c0 .368.301.665.663.665a4.88 4.88 0 0 0 4.874-4.875.663.663 0 0 0-1.329 0 3.548 3.548 0 0 1-3.543 3.545.668.668 0 0 0-.665.665" />
  </IconBase>
);

export default IconOverdraft;
