import {lazy} from "react";
import IRoutes from "../types/IRoutes";

const ListPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'proposte-bene-list'  */ "./BeneProposalsListPage"
    )
);
const NewPage = lazy(
  () => import(/* webpackChunkName: 'proposte-bene-new' */ "./New")
);
const ViewPage = lazy(
  () => import(/* webpackChunkName: 'proposte-bene-view' */ "./View")
);

const routes: IRoutes = [
  {
    children: <ListPage />,
    name: "proposte-bene-list",
    path: "/proposte-bene",
    permission: "bene.read||bene.contractor-read",
    // company: "101",
    // Ulteriore controllo nel componente
  },
  {
    children: <NewPage />,
    name: "proposte-bene-new",
    path: "/proposte-bene/new",
    permission: "bene.manage",
    company: "101",
  },
  {
    children: <ViewPage />,
    name: "proposte-bene-view",
    path: "/proposte-bene/:id",
    permission: "bene.read||bene.contractor-read",
  },
];

export default routes;
