import React from "react";
import IconBase from "./IconBase";

const IconDownload = (props: any) => (
  <IconBase {...props}>
    <path d="M13.5 0h5c.83 0 1.5.67 1.5 1.5V12h5.48c1.11 0 1.67 1.34.88 2.13l-9.5 9.51c-.47.47-1.24.47-1.71 0l-9.52-9.5A1.25 1.25 0 0 1 6.51 12H12V1.5c0-.83.67-1.5 1.5-1.5zM32 23.5v7c0 .83-.67 1.5-1.5 1.5h-29A1.5 1.5 0 0 1 0 30.5v-7c0-.83.67-1.5 1.5-1.5h9.17l3.06 3.06a3.2 3.2 0 0 0 4.54 0L21.33 22h9.17c.83 0 1.5.67 1.5 1.5zM24.25 29A1.25 1.25 0 1 0 23 30.25c.69 0 1.25-.56 1.25-1.25zm4 0A1.25 1.25 0 1 0 27 30.25c.69 0 1.25-.56 1.25-1.25z" />
  </IconBase>
);

export default IconDownload;
