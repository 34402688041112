import {createSelector} from "reselect";
import {getCapsEntities} from "../Caps/selectors";
import {IRootState} from "../redux/reducers";
import {selectLoggedUser, getUserPermissions} from "../User/selectors";
import {getUsersEntities} from "../Users/selectors";
import {ThunkActionsNames} from "./reducers";
import {hydrateProposal} from "./types";

const emptyThunkObject = {};
const emptyArray: any[] = [];

export const getProposalsEntities = (state: IRootState) =>
  state.proposals.entities;

export const getThunkActionsStates = (state: IRootState) =>
  state.proposals.thunkActionsStates;
export const getThunkActionStates = createSelector(
  [
    getThunkActionsStates,
    (state: IRootState, name: ThunkActionsNames) => name,
    (state: IRootState, _: any, key: string = "DEFAULT") => key,
  ],
  (thunkActionsStates, thunkActionName, key) => {
    if (key === "ALL") {
      return thunkActionsStates[thunkActionName] ?? emptyThunkObject;
    }
    return thunkActionsStates[thunkActionName]?.[key] ?? emptyThunkObject;
  }
);

export const getIds = (state: IRootState, ids: ReadonlyArray<string>) => ids;
export const getId = (state: IRootState, id: string | undefined) => id;

export const getProposals = createSelector(
  [getCapsEntities, getProposalsEntities, getUsersEntities, getIds],
  (caps, proposals, users, ids) => {
    return hydrateProposal({
      entities: {caps, proposals, users},
      result: ids,
    });
  }
);
export const getProposal = createSelector(
  [getCapsEntities, getProposalsEntities, getUsersEntities, getId],
  (caps, proposals, users, id) => {
    if (!id || !proposals[id]) {
      return undefined;
    }
    return hydrateProposal({
      entities: {caps, proposals, users},
      result: id,
    });
  }
);

export const getIsAdvisor = createSelector(
  [getProposal, selectLoggedUser],
  (proposal, loggedUser) => {
    if (!proposal || !loggedUser) {
      return false;
    }
    return proposal.user.id === loggedUser.id;
  }
);

export const getIsContractor = createSelector(
  [getProposal, selectLoggedUser],
  (proposal, loggedUser) => {
    if (!proposal || !loggedUser) {
      return false;
    }
    return proposal.contractor.id === loggedUser.id;
  }
);

export const getIsBackOffice = createSelector(
  [getUserPermissions],
  (permissions) => permissions.includes("proposals.approve")
);

export const getIsESignsManagementModalOpen = (state: IRootState) =>
  !!state.proposals.isESignsManagementModalOpen;
export const getOpenFileName = (state: IRootState) => state.proposals.fileName;
export const getOpenForContractor = (state: IRootState) =>
  state.proposals.forContractor;

export const getItemsIds = (state: IRootState) => state.proposals.itemsIds;
export const getLastCreatedId = (state: IRootState) =>
  state.proposals.lastCreatedId;
export const getDuplicatedId = (state: IRootState) =>
  state.proposals.duplicatedId;
export const getChosenCap = (state: IRootState) => state.proposals.chosenCap;

export const getProposalProductDocuments = (state: IRootState) =>
  state.proposals.proposalProductDocuments ?? emptyArray;
