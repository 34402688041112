export const loadDevTools = async () => {
  const explicitlyDisabled =
    window.localStorage.getItem("dev-tools") === "false";
  const explicitlyEnabled = window.localStorage.getItem("dev-tools") === "true";

  if (
    !explicitlyDisabled &&
    (process.env.NODE_ENV === "development" || explicitlyEnabled)
  ) {
    const devTools = await import(
      /* webpackChunkName: 'dev-tools' */ "./dev-tools"
    );
    await devTools.install();
  }
};
