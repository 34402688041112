import React from "react";
import IconBase from "./IconBase";

const IconCheckmark = (props: any) => (
  <IconBase {...props}>
    <path d="M10.87 27.46L.47 17.06a1.6 1.6 0 0 1 0-2.26l2.26-2.26a1.6 1.6 0 0 1 2.26 0l7.01 7 15-15a1.6 1.6 0 0 1 2.27 0l2.26 2.26a1.6 1.6 0 0 1 0 2.26l-18.4 18.4a1.6 1.6 0 0 1-2.26 0z" />
  </IconBase>
);

export default IconCheckmark;
