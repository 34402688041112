import moment from "moment";
import {denormalize, normalize, schema} from "normalizr";
import {
  dehydrateUserProperties,
  hydrateUserProperties,
  IUser,
  IUserDB,
  IUsersEntities,
} from "../Users/types";

export interface IRequestTransactionSnapshot {
  clientUser: string;
  name: string;
  surname: string;
  cell: string;
  email: string;
  fiscalCode: string;
  transactionId: string;
}

export interface IRequestESignSnapshot {
  [key: string]: any;
}

export interface ITransactionId {
  [key: number]: string;
}

// È l'interfaccia dei eSign salvati nello store redux
//  i.e. senza tipi e con gli id delle altre entità
export interface IESignEntity {
  dateRequestEsign?: string;
  dateRequestTransaction: string;
  id: string;
  jsonRequestEsign?: string;
  jsonRequestTransaction: string;
  statusEsign: "0" | "1";
  transactionId: ITransactionId;
  user: string;
}

// È l'interfaccia dei eSign come arrivano dal db
//  i.e. senza tipi ma con le altre entità esplicitate
export interface IESignDB {
  dateRequestEsign?: string;
  dateRequestTransaction: string;
  id: string;
  jsonRequestEsign?: string;
  jsonRequestTransaction: string;
  statusEsign: "0" | "1";
  transactionId: ITransactionId;
  user: IUserDB;
}
// È l'interfaccia dei eSign idratati
//  i.e. con i tipi e con le altre entità esplicitate
export interface IESign {
  dateRequestEsign?: moment.Moment;
  dateRequestTransaction: moment.Moment;
  id: string;
  requestEsign?: IRequestESignSnapshot;
  requestTransaction: IRequestTransactionSnapshot;
  statusEsign: "0" | "1";
  transactionId: ITransactionId;
  user: IUser;
}

export interface IDehydratedESigns {
  result: string[];
  entities: {
    eSigns: IESignsEntities;
    users: IUsersEntities;
  };
}
export interface IDehydratedESign {
  result: string;
  entities: {
    eSigns: IESignsEntities;
    users: IUsersEntities;
  };
}

const userEntity = new schema.Entity("users");
const eSignEntity = new schema.Entity("eSigns", {
  user: userEntity,
});
export const eSignSchema = eSignEntity;

export interface IESignsEntities {
  [key: string]: IESignEntity;
}

export const dehydrateESignProperties = (eSignGeneric: IESign) => {
  const {
    dateRequestEsign,
    dateRequestTransaction,
    requestEsign,
    requestTransaction,
    user,
    ...rest
  } = eSignGeneric;
  const eSign: IESignDB = {
    ...rest,
    dateRequestEsign: dateRequestEsign && dateRequestEsign.format("LL"),
    dateRequestTransaction:
      dateRequestTransaction && dateRequestTransaction.format("LL"),
    jsonRequestEsign: JSON.stringify(requestEsign),
    jsonRequestTransaction: JSON.stringify(requestTransaction),
    user: dehydrateUserProperties(user),
  };

  return eSign;
};

export function normalizeESign(eSign: IESignDB): IDehydratedESign;
export function normalizeESign(eSign: IESignDB[]): IDehydratedESigns;
export function normalizeESign(eSign: IESignDB | IESignDB[]) {
  return normalize(eSign, eSignSchema);
}

export function dehydrateESign(eSign: IESign): IDehydratedESign;
export function dehydrateESign(eSign: IESign[]): IDehydratedESigns;
export function dehydrateESign(eSign: IESign | IESign[]) {
  if (Array.isArray(eSign)) {
    // Risultato multiplo
    return normalizeESign(eSign.map(dehydrateESignProperties));
  } else {
    // Risultato singolo
    return normalizeESign(dehydrateESignProperties(eSign));
  }
}

export const hydrateESignProperties = (eSignGeneric: IESignDB) => {
  const {
    dateRequestEsign,
    dateRequestTransaction,
    jsonRequestEsign,
    jsonRequestTransaction,
    user,
    ...rest
  } = eSignGeneric;
  const eSign: IESign = {
    ...rest,
    dateRequestEsign: dateRequestEsign ? moment(dateRequestEsign) : undefined,
    dateRequestTransaction: moment(dateRequestTransaction),
    requestEsign: jsonRequestEsign
      ? (JSON.parse(jsonRequestEsign) as IRequestESignSnapshot)
      : undefined,
    requestTransaction: JSON.parse(
      jsonRequestTransaction
    ) as IRequestTransactionSnapshot,
    user: hydrateUserProperties(user),
  };

  return eSign;
};

export function hydrateESign({result, entities}: IDehydratedESign): IESign;
export function hydrateESign({result, entities}: IDehydratedESigns): IESign[];
export function hydrateESign({
  result,
  entities,
}: IDehydratedESign | IDehydratedESigns): IESign | IESign[] {
  if (typeof result === "string") {
    // Risultato singolo
    return hydrateESignProperties(denormalize(result, eSignEntity, entities));
  } else {
    // Risultato multiplo
    return denormalize(result, [eSignEntity], entities).map(
      hydrateESignProperties
    );
  }
}

export interface ICreateFEATransactionRequestData {
  pdfType: PDFTypes;
  referenceId: string;
  [key: string]: string;
}

export interface ISignFEADocRequestData {
  OTP: string;
  transactionId: string;
  pdfType: PDFTypes;
  referenceId: string;
  [key: string]: string;
}

/**
 * Enum con i tipi di documento, per ogni tipo dobbiamo inviare l'id dell'entità
 *  corrispondente
 * *
 *  * templatePrivacy - referenceId -> ID USER
 *  * templatePrivacyContractor - referenceId -> ID CONTRACTOR
 *  * templatePrivacySubscription - referenceId -> ID USER
 *  * templateIdentificazione - referenceId -> ID CAP
 *  * templateAnalisi - referenceId -> ID CAP
 *  * templateProposta - referenceId -> ID PROPOSTA
 *  * templateRaccomandazione - referenceId -> ID RACCOMANDAZIONE
 *  * templateDichiarazioneCoerenzaBene - referenceId -> ID PROPOSTA BENE
 *  * templateDichiarazioneCoerenza - ID CLASSE ELEMENTARE
 *  * templateDichiarazioneAvvenutaConsegnaBene - referenceId -> ID PROPOSTA BENE
 *  * templateDichiarazioneAvvenutaConsegna - referenceId -> ID CLASSE ELEMENTARE
 */
export enum PDFTypes {
  Privacy = "templatePrivacy",
  PrivacyContractor = "templatePrivacyContractor",
  PrivacySubscription = "templatePrivacySubscription",
  Identification = "templateIdentificazione",
  Cap = "templateAnalisi",
  Proposal = "templateProposta",
  Recommendation = "templateRaccomandazione",
  BeneConsistency = "templateDichiarazioneCoerenzaBene",
  Consistency = "templateDichiarazioneCoerenza",
  BeneDelivery = "templateDichiarazioneAvvenutaConsegnaBene",
  Delivery = "templateDichiarazioneAvvenutaConsegna",
  BeneMarketing = "templateDichiarazioneCoerenzaBeneMarketing",
}
