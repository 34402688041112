import React from "react";
import IconBase from "./IconBase";

const IconCopy = (props: any) => (
  <IconBase {...props}>
    <path d="M22 28v2.5c0 .8-.7 1.5-1.5 1.5h-17c-.8 0-1.5-.7-1.5-1.5v-23C2 6.7 2.7 6 3.5 6H8v18.5c0 1.9 1.6 3.5 3.5 3.5H22zm0-21.5V0H11.5c-.8 0-1.5.7-1.5 1.5v23c0 .8.7 1.5 1.5 1.5h17c.8 0 1.5-.7 1.5-1.5V8h-6.5c-.8 0-1.5-.7-1.5-1.5zm7.6-1.9L25.5.5c-.3-.3-.7-.5-1.1-.5H24v6h6v-.4c0-.4-.2-.8-.4-1z" />
  </IconBase>
);

export default IconCopy;
