import React from "react";
import IconBase from "./IconBase";

const IconSlide = (props: any) => (
  <IconBase {...props}>
    <path d="M17.398 9.475v13.05a.931.931 0 0 1-.933.934h-.933a.93.93 0 0 1-.932-.934V9.475a.93.93 0 0 1 .932-.933h.933a.93.93 0 0 1 .933.933z" />
    <path d="M12.737 13.514H7.455v-3.407A.775.775 0 0 0 6.13 9.56L.218 15.468a.755.755 0 0 0 0 1.062l5.912 5.91a.775.775 0 0 0 1.325-.547v-3.406h5.283a.93.93 0 0 0 .932-.934v-3.107a.931.931 0 0 0-.933-.932z" />
    <path d="M19.264 18.486h5.281v3.406a.776.776 0 0 0 1.325.547l5.911-5.912a.752.752 0 0 0 0-1.061l-5.911-5.91a.776.776 0 0 0-1.325.548v3.41h-5.281a.93.93 0 0 0-.934.932v3.107c0 .517.416.933.934.933z" />
  </IconBase>
);

export default IconSlide;
