import {createAsyncThunk} from "@reduxjs/toolkit";
import last from "lodash/last";
import {actions as capsActions} from "../../../Caps/actions";
import {createAsyncThunkAndReset} from "../../../helpers/createAsyncThunkAndReset";
import errors, {ErrorCodes} from "../../../helpers/errors";
import {actions as usersActions} from "../../../Users/actions";
import service from "../../service";
import {
  beneMarketingEntityUtility,
  IBeneMarketingEntity,
  IBeneMarketingParams,
  IDataSnapshot,
} from "../../types";
import {actions as entityActions} from "../entities";

export const create = createAsyncThunkAndReset(
  "beneMarketings/create",
  async (
    beneMarketing: Partial<IBeneMarketingEntity> & {
      dateEffect: string;
    },
    thunkAPI
  ) => {
    const createdBeneMarketing = await service.create(beneMarketing);
    const {
      result,
      entities: {users, beneMarketings, caps},
    } = beneMarketingEntityUtility.normalizeEntity(createdBeneMarketing);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(beneMarketings ?? {}));

    return {beneMarketingId: result};
  }
);

// const data = JSON.parse(createdBeneMarketing.jsonData) as IDataSnapshot;
// const lastData = last(data);
// const uid = lastData?.outputAccessoProdotto?.uid;
//
// if (!uid) {
//   // Non abbiamo lo uid
//   return thunkAPI.rejectWithValue({
//     beneMarketingsId: result,
//     error: errors[ErrorCodes.BENE_UID_NOT_VALID],
//   });
// } else if (lastData?.outputAccessoProdotto?.outcome?.messages !== null) {
//   // C'è lo uid ma abbiamo cmq degli errori conosciuti
//   return thunkAPI.rejectWithValue({
//     beneMarketingsId: result,
//     error: {
//       ...errors[ErrorCodes.BENE_NON_BLOCKING_ERROR],
//       message:
//         `Bene Assicurazioni ha tornato questo errore: "${
//           lastData?.outputAccessoProdotto?.outcome?.messages![0]
//         }". ` + errors[ErrorCodes.BENE_NON_BLOCKING_ERROR].message,
//     },
//   });
// }

//   const sentEmailBeneMarketing = await service.sendEmail(result);
//   const {
//     entities: {
//       users: sentEmailUsers,
//       beneMarketings: sentEmailBeneMarketings,
//       caps: sentEmailCaps,
//     },
//   } = beneMarketingEntityUtility.normalizeEntity(sentEmailBeneMarketing);
//
//   thunkAPI.dispatch(usersActions.mergeItems(sentEmailUsers));
//   thunkAPI.dispatch(capsActions.mergeItems(sentEmailCaps));
//   thunkAPI.dispatch(entityActions.received(sentEmailBeneMarketings ?? {}));
//
//   return {
//     beneMarketingsId: result,
//     uid,
//   };
// });

export const get = createAsyncThunk(
  "beneMarketings/get",
  async (id: string, thunkAPI) => {
    const beneMarketing = await service.read(id);
    const {
      result,
      entities: {users, beneMarketings, caps},
    } = beneMarketingEntityUtility.normalizeEntity(beneMarketing);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(beneMarketings ?? {}));

    return {beneMarketingsId: result};
  }
);

export const list = createAsyncThunk(
  "beneMarketings/list",
  async (params: IBeneMarketingParams, thunkAPI) => {
    const {numBeneMarketings, beneMarketingsList} = await service.list(params);
    const {
      result,
      entities: {users, beneMarketings, caps},
    } = beneMarketingEntityUtility.normalizeEntity(beneMarketingsList);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(beneMarketings ?? {}));

    return {beneMarketingsIds: result, numBeneMarketings, params};
  }
);

export const send = createAsyncThunkAndReset<
  {uid?: string; beneMarketingsId: string},
  {id: string},
  {}
>(
  "beneMarketings/resend",
  async (
    {id},
    // dateEffect,
    // jsonProduct,
    // {id: string}, //  dateEffect: string; jsonProduct: string},
    thunkAPI
  ) => {
    const sentBeneMarketing = await service.send(id); // , {
    // dateEffect,
    // jsonProduct,
    // });
    const {
      result,
      entities: {users, beneMarketings, caps},
    } = beneMarketingEntityUtility.normalizeEntity(sentBeneMarketing);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(beneMarketings ?? {}));

    const data = sentBeneMarketing.jsonData
      ? (JSON.parse(sentBeneMarketing.jsonData) as IDataSnapshot)
      : undefined;
    const lastData = last(data);
    const uid = lastData?.outputAccessoProdotto?.uid;

    if (!uid) {
      // Non abbiamo lo uid
      return thunkAPI.rejectWithValue({
        beneMarketingsId: result,
        error: errors[ErrorCodes.BENE_UID_NOT_VALID],
      });
    } else if (lastData?.outputAccessoProdotto?.outcome?.messages !== null) {
      // C'è lo uid ma abbiamo cmq degli errori conosciuti
      return thunkAPI.rejectWithValue({
        beneMarketingsId: result,
        error: {
          ...errors[ErrorCodes.BENE_NON_BLOCKING_ERROR],
          message:
            `Bene Assicurazioni ha tornato questo errore: "${lastData?.outputAccessoProdotto?.outcome?.messages[0]}". ` +
            errors[ErrorCodes.BENE_NON_BLOCKING_ERROR].message,
        },
      });
    }

    return {
      beneMarketingsId: result,
      uid,
    };
  }
);

export const sendEmail = createAsyncThunk(
  "beneMarketings/sendEmail",
  async (elementaryClassId: string, thunkAPI) => {
    const beneMarketing = await service.sendEmail(elementaryClassId);
    const {
      entities: {users, beneMarketings, caps},
    } = beneMarketingEntityUtility.normalizeEntity(beneMarketing);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(beneMarketings ?? {}));
  }
);
