export function yn(value: unknown, defaultValue: boolean): boolean;
export function yn(
  value: unknown,
  defaultValue?: undefined
): boolean | undefined;
export function yn(
  value: unknown,
  defaultValue?: boolean
): boolean | undefined {
  const stringValue = String(value).trim();

  if (/^(?:y|yes|true|1|on)$/i.test(stringValue)) {
    return true;
  }

  if (/^(?:n|no|false|0|off)$/i.test(stringValue)) {
    return false;
  }

  return defaultValue;
}
