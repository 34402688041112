import React from "react";
import IconBase from "./IconBase";

const IconIdFace = (props: any) => (
  <IconBase {...props}>
    <path d="M32 8.4c0-.8-.7-1.5-1.5-1.5H15.4c-.8 0-1.5.7-1.5 1.5v11.1c0 .8.7 1.5 1.5 1.5h15.1c.8 0 1.5-.7 1.5-1.5V8.4zM19.4 10c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm3.5 7.4c0 .3-.3.6-.7.6h-5.6c-.4 0-.7-.3-.7-.6v-.6c0-1 .9-1.8 2.1-1.8h.2c.4.2.8.2 1.2.2.4 0 .8-.1 1.2-.2h.2c1.2 0 2.1.8 2.1 1.8v.6zm7.1-1.7c0 .1-.1.3-.3.3h-4.5c-.1 0-.3-.1-.3-.3v-.5c0-.1.1-.3.3-.3h4.5c.1 0 .3.1.3.3v.5zm0-2c0 .1-.1.3-.3.3h-4.5c-.1 0-.3-.1-.3-.3v-.5c0-.1.1-.3.3-.3h4.5c.1 0 .3.1.3.3v.5zm0-2c0 .1-.1.3-.3.3h-4.5c-.1 0-.3-.1-.3-.3v-.5c0-.1.1-.3.3-.3h4.5c.1 0 .3.1.3.3v.5z" />
    <path d="M15.4 21.4c-1 0-1.9-.8-1.9-1.9v-1.9c-1 .4-2 .6-3.1.6-1.2 0-2.3-.3-3.4-.7h-.8c-3.4 0-6.2 2.8-6.2 6.2v1.9c0 1.2 1 2.2 2.2 2.2h16.3c1.2 0 2.2-1 2.2-2.2v-1.9c0-.8-.2-1.6-.4-2.3h-4.9zM10.4 16c1.1 0 2.2-.3 3.1-.9V8.4c0-1 .8-1.8 1.7-1.9C14.1 5 12.4 4 10.4 4 7.1 4 4.5 6.7 4.5 9.9S7.1 16 10.4 16z" />
  </IconBase>
);

export default IconIdFace;
