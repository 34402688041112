import React from "react";
import IconBase from "./IconBase";

const IconInfoCircle = (props: any) => (
  <IconBase {...props}>
    <path d="M16 2.06a13.94 13.94 0 1 1 0 27.88 13.94 13.94 0 0 1 0-27.88M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32zm-2.32 22.2h.77v-7.75h-.77a.77.77 0 0 1-.78-.77v-.52c0-.43.35-.77.78-.77h3.1c.42 0 .77.34.77.77v9.03h.77c.43 0 .78.35.78.78v.51c0 .43-.35.78-.78.78h-4.64a.77.77 0 0 1-.78-.78v-.51c0-.43.35-.78.78-.78zM16 6.7a2.06 2.06 0 1 0 0 4.14 2.06 2.06 0 0 0 0-4.13z" />{" "}
  </IconBase>
);

export default IconInfoCircle;
