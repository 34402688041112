import React from "react";
import IconBase from "./IconBase";

const IconPlus = (props: any) => (
  <IconBase {...props}>
    <path d="M28 13H4a2 2 0 0 0-2 2v2c0 1.1.9 2 2 2h24a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2z" />
  </IconBase>
);

export default IconPlus;
