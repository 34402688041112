import {FC, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router";
import {actions} from "./slice";

type IRouteState = {
  disableAutoScrollToTop?: boolean;
} | null;

const ScrollToTop: FC = ({children}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Se sei qui per capire perché scrolla in alto anche se
  // disableAutoScrollToTop è true è colpa del route splitting
  // Per risolvere devi implementare un sistema per caricare in
  // anticipo la route dove stai andando
  const disableAutoScrollToTop = (location.state as IRouteState)
    ?.disableAutoScrollToTop;

  useEffect(() => {
    if (!disableAutoScrollToTop) {
      window.scrollTo(0, 0);
    }

    dispatch(actions.closeNav());
  }, [location, disableAutoScrollToTop, dispatch]);

  return <>{children}</>;
};

export default ScrollToTop;
