import React from "react";
import IconBase from "./IconBase";

const IconLock = (props: any) => (
  <IconBase {...props}>
    <path d="M24.75 32a3.128 3.128 0 0 0 3.125-3.125v-13.5a3.132 3.132 0 0 0-2.5-3.063V9.375C25.375 4.205 21.169 0 16 0S6.625 4.205 6.625 9.375v2.938a3.132 3.132 0 0 0-2.5 3.063v3.548a.625.625 0 1 0 1.25 0v-3.548c0-1.034.841-1.875 1.875-1.875h17.5c1.034 0 1.875.841 1.875 1.875v13.5a1.877 1.877 0 0 1-1.875 1.875H7.25a1.877 1.877 0 0 1-1.875-1.875v-4.938a.625.625 0 0 0-1.25 0v4.938A3.128 3.128 0 0 0 7.25 32h17.5zm-13.5-19.75V9.375c0-2.619 2.131-4.75 4.75-4.75s4.75 2.131 4.75 4.75v2.875h-9.5zm10.75 0V9.375c0-3.309-2.691-6-6-6s-6 2.691-6 6v2.875H7.875V9.375c0-4.48 3.645-8.125 8.125-8.125s8.125 3.645 8.125 8.125v2.875H22z" />
    <path d="M4.75 22.03a.629.629 0 0 0 .625-.625.629.629 0 0 0-.625-.624.625.625 0 1 0 0 1.249" />
    <path d="M16 28.625a1.877 1.877 0 0 0 1.875-1.875v-3.318a3.36 3.36 0 0 0 1.508-2.812 3.38 3.38 0 0 0-3.375-3.376 3.38 3.38 0 0 0-3.375 3.376c0 1.137.563 2.177 1.492 2.801v3.329c0 1.034.841 1.875 1.875 1.875m-2.117-8.005c0-1.172.954-2.125 2.125-2.125 1.172 0 2.125.953 2.125 2.125 0 .805-.447 1.53-1.166 1.896a.624.624 0 0 0-.342.558v3.677a.625.625 0 0 1-1.25 0v-3.686a.625.625 0 0 0-.339-.555 2.12 2.12 0 0 1-1.153-1.89" />
  </IconBase>
);

export default IconLock;
