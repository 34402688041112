import React from "react";
import IconBase from "./IconBase";

const IconTrash = (props: any) => (
  <IconBase {...props}>
    <path d="M2 5.25V3.5C2 2.67 2.67 2 3.5 2h7l.59-1.17c.25-.51.77-.83 1.34-.83h7.14c.57 0 1.09.32 1.34.83L21.5 2h7c.83 0 1.5.67 1.5 1.5v1.75c0 .41-.34.75-.75.75H2.75A.75.75 0 0 1 2 5.25zm26 3.5V29a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V8.75c0-.41.34-.75.75-.75h22.5c.41 0 .75.34.75.75zM11 13c0-.55-.45-1-1-1s-1 .45-1 1v14c0 .55.45 1 1 1s1-.45 1-1V13zm6 0c0-.55-.45-1-1-1s-1 .45-1 1v14c0 .55.45 1 1 1s1-.45 1-1V13zm6 0c0-.55-.45-1-1-1s-1 .45-1 1v14c0 .55.45 1 1 1s1-.45 1-1V13z" />
  </IconBase>
);

export default IconTrash;
