import React from "react";
import IconBase from "./IconBase";

const IconHouse = (props: any) => (
  <IconBase {...props}>
    <path d="M31.37 15.6l-3.29-3.08a.56.56 0 1 0-.76.82l3.29 3.08c.17.16.27.37.27.6a.86.86 0 0 1-.86.9.87.87 0 0 1-.6-.24L16.37 5.45a.56.56 0 0 0-.76 0L2.58 17.72a.87.87 0 0 1-1.23-.03.86.86 0 0 1 .04-1.23l14-13.18a.87.87 0 0 1 1.18 0l8.2 7.67c.22.2.57.2.78-.03a.56.56 0 0 0-.02-.79l-8.2-7.67a1.99 1.99 0 0 0-2.7 0l-4.49 4.22v-.75a.88.88 0 0 0-.89-.89h-3.2a.88.88 0 0 0-.9.9l.02 5.43-4.55 4.28a1.97 1.97 0 0 0-.08 2.8c.36.39.85.6 1.38.62h.06c.51 0 .99-.19 1.36-.54l1.73-1.63v11.43c0 .96.8 1.75 1.76 1.75h18.36c.96 0 1.74-.79 1.74-1.75V27l.01-.01-.02-10.13 1.74 1.63a1.98 1.98 0 0 0 2.71-2.9M6.67 6.17h1.96c.21 0 .4.18.4.4v1.17l-2.75 2.59V6.56c0-.22.17-.4.4-.4m6.86 14.04h4.92v8.76h-4.92V20.2zm12.28 8.13c0 .35-.28.63-.63.63h-5.65V20.1a.98.98 0 0 0-.98-.97h-5.13a.97.97 0 0 0-.97.98v8.86H6.83a.64.64 0 0 1-.64-.63V15.85l9.8-9.23 9.82 9.2.01 10.53v1.98z" />
  </IconBase>
);

export default IconHouse;
