import {apiService} from "../App/service";
import {IAdvisorTree, ILoadAdvisorsParams, IRoots} from "./types";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}analytics`;

const analyticsService = {
  loadAdvisorsTree({id, fromDate, toDate}: ILoadAdvisorsParams) {
    return apiService
      .get(`${apiUrl}/tree/${id}`, {
        from_date: fromDate,
        to_date: toDate,
      })
      .then((responseJson) => responseJson.tree as IAdvisorTree);
  },
  loadRoots() {
    return apiService
      .get(`${apiUrl}/roots`)
      .then((responseJson) => responseJson.roots as IRoots[]);
  },
};

export default analyticsService;
