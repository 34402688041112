import React from "react";
import IconBase from "./IconBase";

const IconTimes = (props: any) => (
  <IconBase {...props}>
    <path d="M22.07 16l9.1-9.1a2.86 2.86 0 0 0 0-4.04L29.13.84a2.86 2.86 0 0 0-4.04 0L16 9.94 6.9.84a2.86 2.86 0 0 0-4.04 0L.84 2.86a2.86 2.86 0 0 0 0 4.04l9.1 9.1-9.1 9.1a2.86 2.86 0 0 0 0 4.04l2.02 2.02a2.86 2.86 0 0 0 4.04 0l9.1-9.1 9.1 9.1a2.86 2.86 0 0 0 4.04 0l2.02-2.02a2.86 2.86 0 0 0 0-4.04l-9.1-9.1z" />
  </IconBase>
);

export default IconTimes;
