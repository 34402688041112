import React from "react";
import IconBase from "./IconBase";

const IconChevronUp = (props: any) => (
  <IconBase {...props}>
    <path d="M17.24 6.8l14.24 14.25c.7.68.7 1.8 0 2.48l-1.66 1.66c-.68.69-1.8.7-2.48 0L16 13.92 4.66 25.2c-.69.68-1.8.68-2.48 0L.52 23.52a1.76 1.76 0 0 1 0-2.48L14.76 6.8c.68-.68 1.8-.68 2.48 0z" />
  </IconBase>
);

export default IconChevronUp;
